import React, { useMemo } from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { formatNumberWithSpaces } from '../../GoogleAnalytics/common/HelperFunctions/utils';

const GrossProfitTable = ({ pnl, t }) => {
  // Safe number formatting function
  const getTotalJSX = (value) => {
    return formatNumberWithSpaces(value);
  };

  const monthColumns = [
    "JAN", "FEB", "MAR", "APR", "MAY", "JUN", 
    "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
  ].map((month) => ({
    title: <span style={{ fontWeight: 'bold' }}>{month}</span>,
    dataIndex: month.toLowerCase(),
    key: month.toLowerCase(),
    width: 120,
    render: (value) => (
      <span className="table-cell-text">
        {value === 0 ? '-' : formatNumberWithSpaces(value?.toFixed(2))}
      </span>
    ),
  }));

  const fixedColumns = [
    {
      title: t("TOTAL"),
      dataIndex: 'total2',
      key: 'total2',
      width: 120,
      fixed: 'right',
      render: (value) => (
        <span className="table-cell-text">
          {value === 0 ? '-' : formatNumberWithSpaces(value?.toFixed(2))}
        </span>
      ),
    },
    {
      title: t("AVERAGE"),
      dataIndex: 'average2',
      key: 'average2',
      width: 120,
      fixed: 'right',
      render: (value) => (
        <span className="table-cell-text">
          {value === 0 ? '-' : formatNumberWithSpaces(value?.toFixed(2))}
        </span>
      ),
    },
  ];

  const columns = [
    { title: t("GROSS PROFIT"), dataIndex: 'GrossProfit', key: 'GrossProfit', width: 180 },
    ...monthColumns,
    ...fixedColumns,
  ];

  const data = useMemo(() => {
    const safeNumber = (value) => {
      const num = Number(value);
      return isNaN(num) ? 0 : num;
    };

    const rows = [
      {
        key: '1',
        GrossProfit: <span className="table-cell-text">Gross Profit</span>,
        ...monthColumns.reduce((acc, col, monthIndex) => {
          const monthData = pnl[monthIndex];
          const value = safeNumber(monthData?.gross);
          acc[col.dataIndex] = value;
          return acc;
        }, {}),
        total2: pnl.reduce((sum, item) => sum + safeNumber(item?.gross), 0),
        average2: pnl.reduce((sum, item) => sum + safeNumber(item?.gross), 0) / 12
      },
    ];

    return rows;
  }, [pnl]);

  return (
    <Table
      columns={columns}
      dataSource={data}
      scroll={{ x: 1500 }}
      pagination={false}
    />
  );
};

GrossProfitTable.propTypes = {
  pnl: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default GrossProfitTable;