import React, { useEffect, useState } from 'react';
import { CardBody, Col, Card } from 'reactstrap';
import GoogleAdsCardPercentage from '../GoogleDashboard/Google Ads Overview/GoogleAdsCard/GoogleAdsCardPercentage';
import { useSelector } from 'react-redux';
import { use } from 'echarts';
import PercentageWithCompare from './PercentageWithCompare';
import Tooltip from '../GoogleDashboard/Google Ads Overview/GoogleAdsCard/Tooltip';
import { tooltipMap } from '../../locales/tooltip/tooltip';

const StoreOverviewCard = ({ title, data, percentage }) => {
  return (
    <Col xl={2} className="mb-4">
      <div
        className="h-100"
        style={{ border: '1px solid #D9D5EC', borderRadius: '3px' }}
      >
        <div className="p-3">
          <div className="d-flex flex-column align-items-start justify-content-between gap-3">
            <Tooltip
              displayed={
                <span
                  style={{
                    fontWeight: '700',
                    fontSize: '13px',
                    color: '#0044BD',
                    fontFamily: 'DM Sans, sans-serif',
                    cursor: 'pointer',
                  }}
                >
                  {title}
                </span>
              }
              tooltip={tooltipMap[title]?.tooltip}
              formule={tooltipMap[title]?.formula}
            />
            <div className="d-flex flex-column align-items-start">
              <span
                style={{
                  fontWeight: '700',
                  color: '#000000',
                  fontSize: '21px',
                  fontFamily: 'DM Sans, sans-serif',
                }}
              >
                {data || 0}
              </span>
              {percentage && <PercentageWithCompare percentage={percentage} />}
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default StoreOverviewCard;
