import store from '..';
import {
  SET_GOOGLE_ADS_CURRENCY_FAILURE,
  SET_GOOGLE_ADS_CURRENCY_REQUEST,
  SET_GOOGLE_ADS_CURRENCY_SUCCESS,
  GET_GOOGLE_ADS_CURRENCY_FAILURE,
  GET_GOOGLE_ADS_CURRENCY_REQUEST,
  GET_GOOGLE_ADS_CURRENCY_SUCCESS,
  SET_META_ADS_CURRENCY_FAILURE,
  SET_META_ADS_CURRENCY_REQUEST,
  SET_META_ADS_CURRENCY_SUCCESS,
  GET_META_ADS_CURRENCY_FAILURE,
  GET_META_ADS_CURRENCY_REQUEST,
  GET_META_ADS_CURRENCY_SUCCESS,
  GET_STORE_CURRENCY_REQUEST,
  GET_STORE_CURRENCY_SUCCESS,
  GET_STORE_CURRENCY_FAILURE,
} from './actions';

const initialState = {
  storeCurrency: '',
  googleAdsCurrency: { spend: '', revenue: '' },
  metaAdsCurrency: { spend: '', revenue: '' },
  isLoading: false,
  error: null,
};

export default function CurrencyReducer(state = initialState, action) {
  switch (action.type) {
    case GET_STORE_CURRENCY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_STORE_CURRENCY_SUCCESS:
      return {
        ...state,
        storeCurrency: action.payload,
        isLoading: false,
        error: null,
      };
    case GET_STORE_CURRENCY_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case GET_GOOGLE_ADS_CURRENCY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_GOOGLE_ADS_CURRENCY_SUCCESS:
      return {
        ...state,
        googleAdsCurrency: action.payload,
        isLoading: false,
        error: null,
      };
    case GET_GOOGLE_ADS_CURRENCY_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case SET_GOOGLE_ADS_CURRENCY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SET_GOOGLE_ADS_CURRENCY_SUCCESS:
      return {
        ...state,
        googleAdsCurrency: {
          ...state.googleAdsCurrency,
          revenue: action.payload,
        },
        isLoading: false,
        error: null,
      };
    case SET_GOOGLE_ADS_CURRENCY_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case GET_META_ADS_CURRENCY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_META_ADS_CURRENCY_SUCCESS:
      return {
        ...state,
        metaAdsCurrency: action.payload,
        isLoading: false,
        error: null,
      };
    case GET_META_ADS_CURRENCY_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case SET_META_ADS_CURRENCY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SET_META_ADS_CURRENCY_SUCCESS:
      return {
        ...state,
        metaAdsCurrency: {
          ...state.metaAdsCurrency,
          revenue: action.payload,
        },
        isLoading: false,
        error: null,
      };
    case SET_META_ADS_CURRENCY_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    default:
      return state;
  }
}
