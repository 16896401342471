import { call, put, takeLatest } from 'redux-saga/effects';
import urls from '../../routes/apiUrls';
import ApolloClient from 'apollo-boost';
import {
  addCurrencyRequest,
  getGADSCurrency,
} from '../../queries/Google/googleQueries';
import {
  GET_GOOGLE_ADS_CURRENCY_REQUEST,
  GET_META_ADS_CURRENCY_REQUEST,
  GET_STORE_CURRENCY_REQUEST,
  getGoogleAdsCurrencyFailure,
  getGoogleAdsCurrencySuccess,
  getMetaAdsCurrencyFailure,
  getMetaAdsCurrencySuccess,
  getStoreCurrencyFailure,
  getStoreCurrencySuccess,
  SET_GOOGLE_ADS_CURRENCY_REQUEST,
  SET_META_ADS_CURRENCY_REQUEST,
  setGoogleAdsCurrencyFailure,
  setGoogleAdsCurrencySuccess,
  setMetaAdsCurrencyFailure,
  setMetaAdsCurrencySuccess,
} from './actions';
import {
  addMetaCurrency,
  getMADSCurrency,
} from '../../queries/FacebookAds/queries';
import { getStoreCurrency } from '../../queries/Reports/Overviewreport';

const ApploCLIMETA = new ApolloClient({
  uri: urls.facebook,
});

const ApploCLIGOOGLE = new ApolloClient({
  uri: urls.google,
});

const ApploCLIStore = new ApolloClient({
  uri: urls.report,
});
function* getGoogleAdsCurrency() {
  try {
    const { data } = yield call(ApploCLIGOOGLE.query, {
      query: getGADSCurrency,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    console.log('Received data:', data);
    if (data) {
      yield put(getGoogleAdsCurrencySuccess(data.getCurrency));
    } else {
      yield put(getGoogleAdsCurrencyFailure(data));
    }
  } catch (error) {
    console.error('Error occurred:', error);
    yield put(getGoogleAdsCurrencyFailure('Error occurred'));
  }
}

function* setGoogleAdsCurrency(action) {
  try {
    const { data } = yield call(ApploCLIGOOGLE.mutate, {
      mutation: addCurrencyRequest,
      variables: { currency: action.payload.currency },
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
    });

    console.log('Received data:', data);
    if (data) {
      yield put(setGoogleAdsCurrencySuccess(data.addCurrency.revenue));
    } else {
      yield put(setGoogleAdsCurrencyFailure(data));
    }
  } catch (error) {
    console.error('Error occurred:', error);
    yield put(setGoogleAdsCurrencyFailure('Error occurred'));
  }
}

function* getMetaAdsCurrency() {
  try {
    const { data } = yield call(ApploCLIMETA.query, {
      query: getMADSCurrency,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    console.log('Received data:', data);
    if (data) {
      yield put(getMetaAdsCurrencySuccess(data.getCurrency));
    } else {
      yield put(getMetaAdsCurrencyFailure(data));
    }
  } catch (error) {
    console.error('Error occurred:', error);
    yield put(getMetaAdsCurrencyFailure('Error occurred'));
  }
}

function* setMetaAdsCurrency(action) {
  try {
    const { data } = yield call(ApploCLIMETA.mutate, {
      mutation: addMetaCurrency,
      variables: { currency: action.payload.currency },
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
    });

    if (data) {
     
      yield put(setMetaAdsCurrencySuccess(data.addMetaCurrency.revenue));
    } else {
      yield put(setMetaAdsCurrencyFailure(data));
    }
  } catch (error) {
    console.error('Error occurred:', error);
    yield put(setGoogleAdsCurrencyFailure('Error occurred'));
  }
}

function* getStoreCurrencyRequest() {
  try {
    const { data } = yield call(ApploCLIStore.query, {
      query: getStoreCurrency,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    console.log('Received data:', data);
    if (data) {
      yield put(getStoreCurrencySuccess(data.getStoreCurrency.currency));
    } else {
      yield put(getStoreCurrencyFailure(data));
    }
  } catch (error) {
    console.error('Error occurred:', error);
    yield put(getStoreCurrencyFailure('Error occurred'));
  }
}

export default function* CurrencySaga() {
  yield takeLatest(SET_GOOGLE_ADS_CURRENCY_REQUEST, setGoogleAdsCurrency);
  yield takeLatest(SET_META_ADS_CURRENCY_REQUEST, setMetaAdsCurrency);
  yield takeLatest(GET_GOOGLE_ADS_CURRENCY_REQUEST, getGoogleAdsCurrency);
  yield takeLatest(GET_META_ADS_CURRENCY_REQUEST, getMetaAdsCurrency);
  yield takeLatest(GET_STORE_CURRENCY_REQUEST, getStoreCurrencyRequest);
}
