import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';

const OperationsTable = ({ data, columns }) => {
  return (
    <Table
      dataSource={data}
      columns={columns}  // Use the columns passed as a prop
      pagination={false}
      scroll={{ y: 500 }}
      rowKey="created"
    />
  );
};

OperationsTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,  // Expect columns as a prop
};

export default OperationsTable;
