import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  FormGroup,
  InputGroup,
} from 'reactstrap';
import ApolloClient from 'apollo-boost';
import urls from '../../routes/apiUrls';
import MetaTags from 'react-meta-tags';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import FeatherIcon from 'feather-icons-react';
import {
  getfbPagePosts,
  getfbPageData,
  getfbPageAudience,
} from '../../queries/FacebookSocialMedia/queries';
import {
  getCompareFacebookPageDataPerDate,
  getFacebookPageDataPerDate,
  getFacebookPagePostData,
} from '../../store/facebookPage/actions';
import secureSection from '../../MangeSecurity/MemberSecurity';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import logo from '../../assets/images/Live_Metrics_Logo.png';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FbSocialMediaCards from './fbSocialMediaCards';
import FbSocialMediaPostsTable from './fbSocialMediaPostsTable';
import FbSocialMediaGender from './fbSocialMediaGender';
import FbSocialMediaAge from './fbSocialMediaAge';
import { DateRange } from 'react-date-range';
import { formatDate } from '../GoogleAnalytics/common/HelperFunctions/utils';
import { useSelector, useDispatch } from 'react-redux';
import { logoMap } from '../../assets/integrations_images/logoMap';
import FbSocialMediaActiveBar from './fbSocialMediaActiveBar';

const ApolloCliGetfbSocialMedia = new ApolloClient({
  uri: urls.facebookSocialMedia,
});

function FbSocialMediaDashboard(props) {
  secureSection();
  const dispatch = useDispatch();
  const { facebookPageData, facebookPagePostData } = useSelector(
    (state) => state.facebookPage
  );

  const exportToPDF = async () => {
    try {
      const capture = document.querySelector('.print');
      const canvas = await html2canvas(capture, { useCORS: true, scale: 3 }); // Adjust scale for higher resolution
      const imgData = canvas.toDataURL('image/png', 5.0); // Adjust quality
      const doc = new jsPDF('p', 'mm', 'a4');
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      const padding = 10; // Adjust this value to set the padding in millimeters
      // Add padding to X and Y coordinates
      doc.addImage(
        imgData,
        'PNG',
        1 + padding,
        padding,
        componentWidth - 2 * padding,
        componentHeight - 2 * padding
      );
      // // Customize the appearance of the PDF
      // doc.setLineWidth(0.5); // Set line width for borders
      // doc.setDrawColor(103, 137, 242); // Set border color to black
      // doc.rect(1, 1, componentWidth - 1, componentHeight - 1, 'S'); // Add a border around the content
      doc.save('Rapport-Instagram-Ads.pdf');
    } catch (error) {
      console.error('Error exporting to PDF:', error);
    }
  };
  const [loadingState, setLoadingState] = useState(false);

  const [facebookAudience, setFacebookAudience] = useState([]);
  const [facebookPagePost, setFacebookPagePost] = useState([]);
  // const [facebookPageData, setFacebookPageData] = useState([]);
  const [likes_numbre, setLikes_numbre] = useState([]);
  const [compareLikesNumber, setCompareLikesNumber] = useState([]);
  const [post_impressions, setpost_impressions] = useState([]);
  const [comparePostImpressions, setComparePostImpressions] = useState([]);
  const [post_engagements, setpost_engagements] = useState([]);
  const [comparePostEngagement, setComparePostEngagement] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [compareFollowers, setCompareFollowers] = useState([]);
  const [monthly_impressions, setMonthly_impressions] = useState([]);
  const [compareMonthlyImpressions, setCompareMonthlyImpressions] = useState(
    []
  );
  const [page_impressions_paid, setpage_impressions_paid] = useState([]);
  const [comparePageImpressionsPaid, setComparePageImpressionsPaid] = useState(
    []
  );
  const [page_posts_impressions_paid, setpage_posts_impressions_paid] =
    useState([]);

  const [comparePagePostsImpressionsPaid, setComparePagePostsImpressionsPaid] =
    useState([]);
  const { dateRange } = useSelector((state) => state.dateRangeReducer);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // You can use FileReader to display the selected image
      const reader = new FileReader();
      reader.onload = (event) => {
        setSelectedImage(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    dispatch(getFacebookPagePostData());
  }, []);

  // GET PAGE DATA
  useEffect(() => {
    if (
      facebookPageData &&
      facebookPageData.length > 0 &&
      facebookPageData[0].length > 0
    ) {
      let T_page_posts_impressions_paid = [];
      let T_monthly_impressions = [];
      let T_post_engagements = [];
      let T_post_impressions = [];
      let T_page_impressions_paid = [];
      let T_Likes_number = [];
      let T_Followers = [];

      facebookPageData[0].forEach((item) => {
        T_Followers.push(item.followers);
        T_Likes_number.push(item.likes_numbre);
        T_monthly_impressions.push(item.monthly_impressions);
        T_post_engagements.push(item.post_engagements);
        T_post_impressions.push(item.post_impressions);
        T_page_impressions_paid.push(item.page_impressions_paid);
        T_page_posts_impressions_paid.push(item.page_posts_impressions_paid);
      });

      setLikes_numbre(T_Likes_number);
      setFollowers(T_Followers);
      setpost_engagements(T_post_engagements);
      setpost_impressions(T_post_impressions);
      setMonthly_impressions(T_monthly_impressions);
      setpage_impressions_paid(T_page_impressions_paid);
      setpage_posts_impressions_paid(T_page_posts_impressions_paid);
      let TCompareLikesNumber = [];
      let TCompareFollowers = [];
      let TCompareMonthlyImpressions = [];
      let TComparePostEngagement = [];
      let TComparePostImpressions = [];
      let TComparePageImpressionsPaid = [];
      let TComparePagePostsImpressionsPaid = [];
      if (facebookPageData[1] && facebookPageData[1].length > 0) {
        facebookPageData[1].forEach((item) => {
          TCompareFollowers.push(item.followers);
          TCompareLikesNumber.push(item.likes_numbre);
          TCompareMonthlyImpressions.push(item.monthly_impressions);
          TComparePostEngagement.push(item.post_engagements);
          TComparePostImpressions.push(item.post_impressions);
          TComparePageImpressionsPaid.push(item.page_impressions_paid);
          TComparePagePostsImpressionsPaid.push(
            item.page_posts_impressions_paid
          );
        });
      }
      setCompareLikesNumber(TCompareLikesNumber);
      setCompareFollowers(TCompareFollowers);
      setCompareMonthlyImpressions(TCompareMonthlyImpressions);
      setComparePostEngagement(TComparePostEngagement);
      setComparePostImpressions(TComparePostImpressions);
      setComparePageImpressionsPaid(TComparePageImpressionsPaid);
      setComparePagePostsImpressionsPaid(TComparePagePostsImpressionsPaid);
    }
  }, [facebookPageData]);

  useEffect(() => {
    if (dateRange.selection1.startDate && dateRange.selection1.endDate) {
      const newStartDate = formatDate(dateRange.selection1.startDate);
      const newEndDate = formatDate(dateRange.selection1.endDate);
      if (dateRange.selection2.startDate && dateRange.selection2.startDate) {
        const compareStartDateFormatted = formatDate(
          dateRange.selection2.startDate
        );
        const compareEndDateFormatted = formatDate(
          dateRange.selection2.endDate
        );
        dispatch(
          getCompareFacebookPageDataPerDate(
            newStartDate,
            newEndDate,
            compareStartDateFormatted,
            compareEndDateFormatted
          )
        );
      } else dispatch(getFacebookPageDataPerDate(newStartDate, newEndDate));
    }
  }, [dateRange]);

  const [secCards, setsecCards] = useState([
    {
      id: 2,
      name: 'Likes',
      data: likes_numbre,
      compareData: compareLikesNumber,
    },
    {
      id: 3,
      name: 'Followers',
      data: followers,
      compareData: compareFollowers,
    },

    {
      id: 4,
      name: 'Monthly Impressions',
      data: monthly_impressions,
      compareData: compareMonthlyImpressions,
    },
    {
      id: 5,
      name: 'Post Engagements',
      data: post_engagements,
      compareData: comparePostEngagement,
    },
    {
      id: 6,
      name: 'Post Impressions',
      data: post_impressions,
      compareData: comparePostImpressions,
    },
    {
      id: 7,
      name: 'Page Impressions Paid',
      data: page_impressions_paid,

      compareData: comparePageImpressionsPaid,
    },
    {
      id: 8,
      name: 'Page Posts Impressions Paid',
      data: page_posts_impressions_paid,
      compareData: comparePagePostsImpressionsPaid,
    },
  ]);

  const colors = [
    {
      lineColor: '#4A3AFF',
      fillColor: [
        {
          offset: 0,
          color: '#93AAFD',
        },
        {
          offset: 0.47,
          color: '#C6D2FD',
        },
        {
          offset: 1,
          color: '#E5EAFC4F',
        },
      ],
    },
    {
      lineColor: '#F6B43B',
      fillColor: [
        {
          offset: 0,
          color: ' #F6B43B',
        },

        {
          offset: 1,
          color: '#D9D9D9',
        },
      ],
    },
  ];

  useEffect(() => {
    let isSubscribed = true;
    setsecCards([
      {
        id: 2,
        name: 'Likes',
        data: likes_numbre,
        compareData: compareLikesNumber,
      },
      {
        id: 3,
        name: 'Followers',
        data: followers,
        compareData: compareFollowers,
      },

      {
        id: 4,
        name: 'Monthly Impressions',
        data: monthly_impressions,
        compareData: compareMonthlyImpressions,
      },
      {
        id: 5,
        name: 'Post Engagements',
        data: post_engagements,
        compareData: comparePostEngagement,
      },
      {
        id: 6,
        name: 'Post Impressions',
        data: post_impressions,
        compareData: comparePostImpressions,
      },
      {
        id: 7,
        name: 'Page Impressions Paid',
        data: page_impressions_paid,

        compareData: comparePageImpressionsPaid,
      },
      {
        id: 8,
        name: 'Page Posts Impressions Paid',
        data: page_posts_impressions_paid,
        compareData: comparePagePostsImpressionsPaid,
      },
    ]);
    return () => (isSubscribed = false);
  }, [
    likes_numbre,
    followers,
    monthly_impressions,
    post_impressions,
    post_engagements,
    page_impressions_paid,
    page_posts_impressions_paid,
    compareLikesNumber,
    compareFollowers,
    compareMonthlyImpressions,
    comparePostEngagement,
    comparePostImpressions,
    comparePageImpressionsPaid,
    comparePagePostsImpressionsPaid,
  ]);
  return (
    <>
      <MetaTags>
        <title>
          {props.t('Facebook Social Media Dashboard')} | Convergen-Metrics -
          E-com SaaS
        </title>
      </MetaTags>
      {/* <Card className="tamour mb-5">
          <CardBody className="print">
            <div>
              <div className="invoice-title">
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    src={facebookPageData[0]?.picture}
                    alt=""
                    height="90"
                    width="90"
                    style={{ borderRadius: '50%' }}
                  />
                </div>
              </div>
            </div>
          </CardBody>
        </Card> */}
      <div className="page-content">
        <FbSocialMediaActiveBar />
        <Container fluid className="print p-4">
          <Breadcrumbs
            title={props.t('Facebook Social Media Dashboard')}
            pageHeading={props.t('Facebook Page Dashboard')}
            image={logoMap['Facebook Social Media']}
            breadcrumbItem={props.t('Insights')}
          />
          <Row className=" justify-content-center my-5">
            <FbSocialMediaCards secCards={secCards} colors={colors} />
          </Row>
          <Breadcrumbs
            title={props.t('Facebook Page posts last 30 days')}
            pageHeading={props.t('Facebook Page Dashboard')}
            image={logoMap['Facebook Social Media']}
            breadcrumbItem={props.t('Posts')}
          />
          <Row>
            <FbSocialMediaPostsTable dataSource={facebookPagePostData} />
          </Row>
          <Row>
            
            <hr />
            <div className="d-print-none mt-4">
              <div className="float-end">
                {/* <button
                  type="button"
                  onClick={exportToPDF('Rapport-Instagram-Ads.pdf')}
                  className="btn btn-primary waves-effect waves-light w-md"
                >
                  <div className="d-flex align-items-center">
                    {' '}
                    {/* Use Flexbox to align icon and text */}
                {/* <i
                      className="mdi mdi-download d-block font-size-16"
                      style={{ color: 'white' }}
                    ></i>
                    <span className="ms-2">{props.t('Download')}</span>{' '}  */}
                {/* Add margin for space between icon and text */}
                {/* </div>
                </button> */}
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
}
FbSocialMediaDashboard.propTypes = {
  t: PropTypes.any,
};
export default withRouter(withTranslation()(FbSocialMediaDashboard));
//export default FbSocialMediaDashboard
