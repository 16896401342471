import { Grid } from 'antd';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

const BarChart = ({
  data,
  comparedData, //  the data should be received like this [[item,count],[item,count]]
  dataKey, //the category that will be displayed on the chart
  vertical = true,
  width,
  title,
  color,
}) => {
  const labels = data.map(([item]) => item);
  const dataCounts = data.map(([, count]) => count);
  const comparedDataCounts = comparedData.map(([, count]) => count);
  const chartOptions = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !vertical,
        borderRadius: 2,
        columnWidth: '60%',
        borderRadiusApplication: 'top',
      },
    },
    grid: {
      yaxis: {
        lines: {
          show: vertical,
        },
      },
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        console.log(series);
        return `
        <div style="border: 2px solid ${color}; padding: 10px; border-radius: 3px; background-color: white; font-family: 'DM Sans', sans-serif;">
          <div style="font-size: 14px; color: black; margin-bottom: 5px; font-weight: 500;">${w.globals.labels[
            dataPointIndex
          ].toUpperCase()}</div>
          <div style="display: flex; align-items: center;">
            <span style="background-color: ${color}; width: 9px; height: 9px; display: inline-block; margin-right: 8px; border-radius: 2px"></span>
            <span style="font-size: 14px; color: #25213B; font-family:'DM Sans', sans-serif; font-weight: 500;">${title} : </span>
            <span style="font-size: 16px; font-weight: bold; color: black; margin-left: 5px;">${
              series[seriesIndex][dataPointIndex]
            }</span>
          </div>
        </div>
      `;
      },
    },
    xaxis: {
      categories: labels,
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: 'black',
          fontWeight: 500,
          fontSize: '14px',
          fontFamily: 'Nunito sans, sans-serif',
        },
      },
    },
    colors: [
      function ({ value, seriesIndex, dataPointIndex, w }) {
        console.log('here are the serie index', seriesIndex);
        if (!vertical) {
          if (dataPointIndex % 2 === 1) {
            return '#FFB793';
          } else {
            return '#FF925B';
          }
        }
        return color;
      },
      '#3ECF8E',
    ],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        align: 'center',
        style: {
          colors: 'black',
          fontWeight: 500,
          fontSize: '14px',
          fontFamily: 'Nunito sans, sans-serif',
        },
      },
      decimalsInFloat: 2,
    },
    title: {
      text: title,
      align: 'left',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: '15px',
        fontWeight: 500,
        fontFamily: 'Inter, sans-serif',
        color: '#9291A5',
      },
    },
  };

  const chartSeries = [
    {
      name: dataKey,
      data: dataCounts,
    },
    ...(comparedDataCounts.length > 0
      ? [
          {
            name: `Compared ${dataKey}`,
            data: comparedDataCounts,
          },
        ]
      : []),
  ];
  return (
    <ReactApexChart
      options={chartOptions}
      series={chartSeries}
      type="bar"
      width={width}
      height={438}
    />
  );
};

export default BarChart;
