import { Button, Checkbox, Dropdown, Menu, Space, Table } from 'antd';
import React, { useState } from 'react';
import i18next from 'i18next';
import InstagramTable from './InstagramTable';

const InstagramLogo = `${process.env.PUBLIC_URL}/images/integrations_images/Insta1.svg`;

const InstagramSocialMediaTable = ({ instaPostData,loading }) => {
  const [sortedInfo, setSortedInfo] = useState({});

  // Initialize selectedColumns with all column keys

  const columns = [
    {
      title: 'Post Image',
      dataIndex: 'media_url',
      key: 'media_url',
      align: 'center',

      ellipsis: true,
      render: (text, record) => (
        <a href={record.permalink} target="_blank" rel="noopener noreferrer">
          {text ? (
            <img
              src={text}
              alt={record.full_picture}
              style={{ maxWidth: '100px', maxHeight: '90px' }}
            />
          ) : (
            <span>{' There is Non-image '}</span>
          )}
        </a>
      ),
    },
    {
      title: 'Post Interactions',
      dataIndex: 'total_interactions',
      key: 'total_interactions',
      sorter: (a, b) => a.total_interactions - b.total_interactions,
      sortOrder:
        sortedInfo.columnKey === 'total_interactions' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: 'Post Saves',
      dataIndex: 'saved',
      align: 'center',
      key: 'saved',
      sorter: (a, b) => a.saved - b.saved,
      sortOrder: sortedInfo.columnKey === 'saved' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: 'Post Impressions',
      dataIndex: 'impressions',
      key: 'impressions',
      align: 'center',
      sorter: (a, b) => a.impressions - b.impressions,
      sortOrder:
        sortedInfo.columnKey === 'impressions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{text || '-'}</strong>, // Render '-' if text is falsy
    },
    {
      title: 'Post Reach',
      dataIndex: 'reach',
      align: 'center',
      key: 'reach',
      sorter: (a, b) => a.reach - b.reach,
      sortOrder: sortedInfo.columnKey === 'reach' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: 'Post Comments',
      dataIndex: 'comments',
      key: 'comments',
      align: 'center',
      sorter: (a, b) => a.comments - b.comments,
      sortOrder: sortedInfo.columnKey === 'comments' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: 'Post Likes',
      dataIndex: 'likes',
      key: 'likes',
      sorter: (a, b) => a.likes - b.likes,
      sortOrder: sortedInfo.columnKey === 'likes' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: 'Post Plays',
      dataIndex: 'plays',
      align: 'center',
      key: 'plays',
      sorter: (a, b) => a.plays - b.plays,
      sortOrder: sortedInfo.columnKey === 'plays' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{text || '-'}</strong>,
    },
    {
      title: 'Post Shares',
      dataIndex: 'shares',
      align: 'center',
      key: 'shares',
      sorter: (a, b) => a.shares - b.shares,
      sortOrder: sortedInfo.columnKey === 'shares' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: 'Created At',
      dataIndex: 'timestamp',
      align: 'center',
      key: 'timestamp',
      sorter: (a, b) => new Date(a.timestamp) - new Date(b.timestamp),
      ellipsis: true,
      render: (text) => {
        const date = new Date(text);
        const formattedDate = date.toISOString().split('T')[0]; // Extracts date portion
        return <strong>{formattedDate}</strong>;
      },
    },
  ];

  return (
    <InstagramTable
      columns={columns}
      data={instaPostData}
      sortedInfo={sortedInfo}
      setSortedInfo={setSortedInfo}
      overview={false}
      pageSize="10"
      loading={loading}
    />
  );
};

export default InstagramSocialMediaTable;
