import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import ApolloClient from 'apollo-boost';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Paiment, verifPaiment } from '../../../queries/Users/userQuery';
import urls from '../../../routes/apiUrls';
import confetti from 'canvas-confetti'; // Import canvas-confetti
import secureSection from '../../../MangeSecurity/MemberSecurity';
  

// Apollo Client
const apolloClient = new ApolloClient({
  uri: urls.user,
});

// Utility hook for query parameters
const useQuery = () => new URLSearchParams(useLocation().search);

const Pricing = () => {
  const user = useSelector((state) => state.editProfile);
  secureSection();
  const currentPlan = user.user.PlanType || ''; // Default to empty string if no plan
  const [billingPeriod, setBillingPeriod] = useState('monthly');
  const query = useQuery();

  const plans = [
    {
      name: 'Essential',
      price: { monthly: '299000TND', yearly: '350000TND' },
      priceD: { monthly: '299TND', yearly: '3500TND' },
      descriptionHeader: 'All starter features, plus:',
      features: [
        'Real-time Profit Dashboard',
        'Users: 1',
        'Integrations: All platforms',
        'Reporting: basic',
        'CogsZones: products only',
        'Access to historical data: Non',
      ],
      isPopular: true,
      duration: '/month',
      description: 'Best for business use',
    },
    {
      name: 'Premium',
      price: { monthly: '499000TND', yearly: '4700000TND' },
      priceD: { monthly: '499TND', yearly: '4170TND' },
      descriptionHeader: 'All free features, plus:',
      features: [
        'Real-time Profit Dashboard',
        'Users: 2',
        'Integrations: All platforms',
        'Reporting: Profit reports, creative reports and social media',
        'CogsZones: products only',
        'Access to historical data: 1 Year',
      ],
      duration: '/month',
      description: 'Best for personal use',
    },
    {
      name: 'Full Metrics',
      price: { monthly: '999000TND', yearly: '90000TND' },
      priceD: { monthly: '999TND', yearly: '9990TND' },
      descriptionHeader: 'All free features, plus:',
      features: [
        'Real-time Profit Dashboard',
        'Users: 5',
        'Integrations: All platforms',
        'Reporting: Profit reports, creative reports and social media',
        'CogsZones: products only',
        'Access to historical data: 2 Years',
      ],
      duration: '/month',
      description: 'Best for personal use',
    },
  ];

  // Utility function to extract and convert price to integer
  const parsePrice = (price) => parseInt(price.replace('TND', ''), 10);

  // Function to handle payment
  const handlePayment = async (amount, type, period) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: Paiment,
        variables: {
          amount: parsePrice(amount),
          Type: type,
          Period: period,
        },
        context: {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
          },
        },
      });

      const { link, payment_id } = data.paiement;
      const queryParams = new URLSearchParams({ amount, type, payment_id }).toString();
      window.location.href = `${link}?${queryParams}`;
    } catch (error) {
      console.error('Payment mutation error:', error);
      message.error('Payment initiation failed.');
    }
  };

  // Function to verify payment after redirection
  const verifyPayment = async (paymentId, type, amount, period) => {
    try {
      const { data } = await apolloClient.query({
        query: verifPaiment,
        variables: { id_paiement: paymentId, Type: type, Amount: amount, Period: period },
        context: {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
          },
        },
      });

      if (data.verif_paiement.success === 'SUCCESS') {
        message.success('Payment successful!');
        // i want to clear the url from params 
        const count = 200;
      const defaults = { origin: { y: 0.7 } };
// clear the url from params
        window.history.replaceState({}, document.title, window.location.pathname);
      function fire(particleRatio, opts) {
        confetti({
          ...defaults,
          ...opts,
          particleCount: Math.floor(count * particleRatio),
        });
      }

      // Fire confetti bursts
      fire(0.25, { spread: 26, startVelocity: 55 });
      fire(0.2, { spread: 60 });
      fire(0.35, { spread: 100, decay: 0.91, scalar: 0.8 });
      fire(0.1, { spread: 120, startVelocity: 25, decay: 0.92, scalar: 1.2 });
      fire(0.1, { spread: 120, startVelocity: 45 });
      // clear  url from params
        
      window.location.reload();

      } else {
        message.error('Payment failed.');
      }
    } catch (error) {
      console.error('Payment verification error:', error);
      message.error('Payment failed.');
    }
  };

  const paymentId = query.get('payment_id');
  const planType = query.get('Type');
  const amount = query.get('amount');

  useEffect(() => {
    console.log("paymentId",paymentId)
    if (paymentId) {
      verifyPayment(paymentId, planType, amount, billingPeriod);
    }
  }, [paymentId]);

  const billingToggleStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  };

  const buttonStyle = (active) => ({
    backgroundColor: active ? '#2563EB' : 'transparent',
    color: active ? '#ffffff' : '#999999',
    border: 'none',
    height: '32px',
  });

  return (
    <div className="pricing-page">
      <div className="pricing-header">
        <h1>Pricing</h1>
        <p>Choose the subscription you want to sign up for and turn your data into opportunities!</p>
      </div>

      <div className="billing-toggle" style={billingToggleStyle}>
        <span style={{ color: '#2563EB', fontWeight: '500', marginRight: '12px' }}>
          <span className="fw-bold">Save 15%</span> on yearly plan!
        </span>
        <div style={{ display: 'flex', borderRadius: '5px', border: '1px solid #d9d9d9' }}>
          <Button
            onClick={() => setBillingPeriod('yearly')}
            style={buttonStyle(billingPeriod === 'yearly')}
          >
            Yearly
          </Button>
          <Button
            onClick={() => setBillingPeriod('monthly')}
            style={buttonStyle(billingPeriod === 'monthly')}
          >
            Monthly
          </Button>
        </div>
      </div>

      <div className="plan-cards" style={{ display: 'flex', justifyContent: 'center', gap: '20px',  }}>
        {plans.map((plan, index) => (
          <div
            key={index}
            className={`plan-card ${plan.isPopular ? 'popular' : ''}`}
            style={{
              border: currentPlan === plan.name ? '2px solid #3ECF8E' : plan.isPopular ? '2px solid #0064E0' : '1px solid #E0E0E0',
              borderRadius: '6px',
              padding: '20px',
              width: '340px',
              position: 'relative',
              textAlign: 'center',
              backgroundColor: '#fff',
              opacity: currentPlan === plan.name ? '1' : plan.isPopular ? '1' : '0.3',
              
                       }}
          >
            {plan.isPopular && (
              <div className={currentPlan === plan.name ? 'popular-badge-current-plan' : 'popular-badge'}>
                {currentPlan === plan.name ? 'Current Plan' : 'Most Popular'}
              </div>
            )}
            <h2>{plan.name}</h2>
            <p>{plan.description}</p>
            <p className="price">
              {plan.priceD[billingPeriod]}<span style={{ fontSize: '16px', fontWeight: '400', color: '#A5A5A5' }}> /{billingPeriod}</span>
            </p>

            <Button
              style={{
                backgroundColor: currentPlan === plan.name ? '#3ECF8E' : '#0064E0',
                color: '#ffffff',
                border: 'none',
                width: '100%',
                height: '40px',
                //disalbe the button if is nor plan.popular
                pointerEvents: plan.isPopular ? 'auto' : 'none',
              }}
              onClick={() => currentPlan !== plan.name && handlePayment(plan.price[billingPeriod], plan.name, billingPeriod)}
            >
              {currentPlan === plan.name ? 'Current Plan' : 'Choose Plan'}
            </Button>

            <div className="features" style={{ textAlign: 'left', marginTop: '20px' }}>
              <span className="descriptionheader">{plan.descriptionHeader}</span>
              {plan.features.map((feature, i) => (
                <p key={i} style={{ color: '#000', display: 'flex', alignItems: 'center' }}>
                  <i
                    className={`fas fa-check`}
                    style={{
                      color: currentPlan === plan.name ? '#3ECF8E' : '#0064E0',
                      marginRight: '8px',
                    }}
                  ></i>
                  {feature}
                </p>
              ))}
            </div>
          </div>
        ))}
      </div>

      <p className="support-info">
        For More Information Please Contact <a href="mailto:admin@live-metrics.tn">admin@live-metrics.tn</a>
      </p>
    </div>
  );
};

export default Pricing;
