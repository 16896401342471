import React, { useEffect, useState } from 'react';
import secureSection from '../../../MangeSecurity/MemberSecurity';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from '../../GoogleAnalytics/common/HelperFunctions/utils';
import {
  getGoogleMetrics,
  getGoogleMetricsCompared,
} from '../../../store/googleAds/actions';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import MetaTags from 'react-meta-tags';
import GoogleAdsChart from './GoogleAdsChart/GoogleAdsChart';
import { Col, Row } from 'reactstrap';
import GoogleAdsLogo from '../../../assets/integrations_images/GoogleAdsLogo.svg';
import GoogleAdsCards from './GoogleAdsCards';
import { Spin } from 'antd';

const GoogleDashboard = () => {
  secureSection(); // Security check

  const dispatch = useDispatch();
  const { DataDashboard } = useSelector((state) => state.GoogleAds);
  const { dateRange } = useSelector((state) => state.dateRangeReducer);
  const { isLoading } = useSelector((state) => state.CurrencyReducer);
  const { isLoading: isLoadingAds } = useSelector((state) => state.GoogleAds);

  const [cost, setCost] = useState([]);
  const [compareCost, setCompareCost] = useState([]);

  const [revenue, setRevenue] = useState([]);
  const [compareRevenue, setCompareRevenue] = useState([]);

  const [conversions, setConversions] = useState([]);
  const [compareConversions, setCompareConversions] = useState([]);

  const [impressions, setImpressions] = useState([]);
  const [compareImpressions, setCompareImpressions] = useState([]);

  const [clicks, setClicks] = useState([]);
  const [compareClicks, setCompareClicks] = useState([]);

  const [ROAS, setROAS] = useState([]);
  const [compareROAS, setCompareROAS] = useState([]);

  const [cpa, setCPA] = useState([]);
  const [compareCPA, setCompareCPA] = useState([]);

  const [cpc, setCPC] = useState([]);
  const [compareCPC, setCompareCPC] = useState([]);

  const [ctr, setCTR] = useState([]);
  const [compareCTR, setCompareCTR] = useState([]);

  const [conversionRate, setConversionRate] = useState([]);
  const [compareConversionRate, setCompareConversionRate] = useState([]);

  const [AOV, setAOV] = useState([]);
  const [compareAOV, setCompareAOV] = useState([]);

  useEffect(() => {
    if (DataDashboard?.DataDashboard?.report.length > 0) {
      let TtotalCost = [];
      let Trevenue = [];
      let Tconversions = [];
      let Timpressions = [];
      let Tclicks = [];
      let TROAS = [];
      let Tcpa = [];
      let Tcpc = [];
      let Tctr = [];
      let Tconversionrate = [];
      let TAOV = [];

      DataDashboard.DataDashboard.report[0].forEach((item) => {
        TtotalCost.push(item.totalCost || 0);
        Trevenue.push(item.Revenue || 0);
        Tconversions.push(item.totalConversions);
        Timpressions.push(item.totalImpressions);
        Tclicks.push(item.totalClicks);
        TROAS.push(item.ROAS);
        Tcpa.push(item.cpa);
        Tcpc.push(item.cpc);
        Tctr.push(item.ctr);
        Tconversionrate.push(item.conversionRate);
        TAOV.push(item.AOV);
      });

      setCost(TtotalCost);
      setRevenue(Trevenue);
      setConversions(Tconversions);
      setImpressions(Timpressions);
      setClicks(Tclicks);
      setROAS(TROAS);
      setCPA(Tcpa);
      setCPC(Tcpc);
      setCTR(Tctr);
      setConversionRate(Tconversionrate);
      setAOV(TAOV);

      // Check if there is a second report for comparison
      let TcompareCost = [];
      let TcompareRevenue = [];
      let TcompareConversions = [];
      let TcompareImpressions = [];
      let TcompareClicks = [];
      let TcompareROAS = [];
      let TcompareCPA = [];
      let TcompareCPC = [];
      let TcompareCTR = [];
      let TcompareConversionRate = [];
      let TcompareAOV = [];
      if (DataDashboard?.DataDashboard?.report.length > 1) {
        DataDashboard.DataDashboard.report[1].forEach((item) => {
          TcompareCost.push(item.totalCost || 0);
          TcompareRevenue.push(item.Revenue || 0);
          TcompareConversions.push(item.totalConversions);
          TcompareImpressions.push(item.totalImpressions);
          TcompareClicks.push(item.totalClicks);
          TcompareROAS.push(item.ROAS);
          TcompareCPA.push(item.cpa);
          TcompareCPC.push(item.cpc);
          TcompareCTR.push(item.ctr);
          TcompareConversionRate.push(item.conversionRate);
          TcompareAOV.push(item.AOV);
        });
      }
      setCompareCost(TcompareCost);
      setCompareRevenue(TcompareRevenue);
      setCompareConversions(TcompareConversions);
      setCompareImpressions(TcompareImpressions);
      setCompareClicks(TcompareClicks);
      setCompareROAS(TcompareROAS);
      setCompareCPA(TcompareCPA);
      setCompareCPC(TcompareCPC);
      setCompareCTR(TcompareCTR);
      setCompareConversionRate(TcompareConversionRate);
      setCompareAOV(TcompareAOV);
    }
  }, [DataDashboard]);

  const series = [
    { name: 'spend', data: cost, value: 'Spend', compareData: compareCost },
    {
      name: 'revenue',
      data: revenue,
      value: 'Revenue',
      compareData: compareRevenue,
    },
    {
      name: 'conversions',
      data: conversions,
      value: 'Conversions',
      compareData: compareConversions,
    },
    {
      name: 'impressions',
      data: impressions,
      value: 'Impressions',
      compareData: compareImpressions,
    },
    {
      name: 'clicks',
      data: clicks,
      value: 'Clicks',
      compareData: compareClicks,
    },
  ]; //needed for the main chart

  const cardSeries = [
    {
      name: 'Ad Spend',
      data: cost,
      compareData: compareCost,
    },
    {
      name: 'Revenue',
      data: revenue,
      compareData: compareRevenue,
    },
    {
      name: 'Conversions',
      data: conversions,
      compareData: compareConversions,
    },
    {
      name: 'ROAS',
      data: ROAS,
      compareData: compareROAS,
    },
    {
      name: 'CPA',
      data: cpa,
      compareData: compareCPA,
    },
    {
      name: 'CPC',
      data: cpc,
      compareData: compareCPC,
    },
    {
      name: 'CTR',
      data: ctr,
      compareData: compareCTR,
    },
    {
      name: 'Conversion Rate',
      data: conversionRate,
      compareData: compareConversionRate,
    },
    {
      name: 'Impressions',
      data: impressions,
      compareData: compareImpressions,
    },
    {
      name: 'Clicks',
      data: clicks,
      compareData: compareClicks,
    },
    {
      name: 'AOV',
      data: AOV,
      compareData: compareAOV,
    },
  ];

  useEffect(() => {
    if (dateRange?.selection1?.startDate && dateRange?.selection1?.endDate) {
      const newStartDate = formatDate(dateRange.selection1.startDate);
      const newEndDate = formatDate(dateRange.selection1.endDate);

      if (dateRange.selection2.startDate && dateRange.selection2.endDate) {
        const newCompareStartDate = formatDate(dateRange.selection2.startDate);
        const newCompareEndDate = formatDate(dateRange.selection2.endDate);
        dispatch(
          getGoogleMetricsCompared(
            newStartDate,
            newEndDate,
            newCompareStartDate,
            newCompareEndDate
          )
        );
      } else {
        dispatch(getGoogleMetrics(newStartDate, newEndDate));
      }
    }
  }, [dateRange]);
  const colors = [
    {
      lineColor: '#4A3AFF',
      fillColor: [
        {
          offset: 0,
          color: '#93AAFD',
        },
        {
          offset: 0.47,
          color: '#C6D2FD',
        },
        {
          offset: 1,
          color: '#E5EAFC4F',
        },
      ],
    },
    {
      lineColor: '#F6B43B',
      fillColor: [
        {
          offset: 0,
          color: ' #F6B43B',
        },

        {
          offset: 1,
          color: '#D9D9D9',
        },
      ],
    },
  ];

  return (
    <div className="page-content">
      <MetaTags>
        <title>GoogleAds Dashboard | Convergen-Metrics - E-com SaaS</title>
      </MetaTags>
      <div className="container-fluid">
        <Spin spinning={isLoading || isLoadingAds} size="large">
          <Breadcrumbs
            title="Google Ads"
            breadcrumbItem="Google Dashboard"
            pageHeading="Google Ads Campaigns"
            style={{ fontSize: '15px', fontWeight: '700' }}
            image={GoogleAdsLogo}
          />

          <Row>
            <Col xl={12}>
              <GoogleAdsChart series={series} colors={colors} />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <GoogleAdsCards data={cardSeries} colors={colors} />
          </Row>
        </Spin>
      </div>
    </div>
  );
};

export default GoogleDashboard;
