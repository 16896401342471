import { Button, Checkbox, Dropdown, Input, Menu, Space, Table } from 'antd';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { set } from 'react-hook-form';

const AdTableSingleSearch = ({
  data,
  columns,
  setSortedInfo,
  sortedInfo,
  overview = false,
  loading,
  statusMapping,
}) => {
  const [filteredData, setFilteredData] = useState(data);
  useEffect(() => {
    setFilteredData(data);
  }, [data]);
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((column) => column.key)
  );

  const [statusFilter, setStatusFilter] = useState({
    ENABLED: false,
    PAUSED: false,
  });

  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 5, // Default page size
    pageSizeOptions: ['5', '10', '20'], // Available page size options
  });

  const [searchText, setSearchText] = useState('');

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    const updatedFilter = {
      ...statusFilter,
      [name]: checked,
    };

    setStatusFilter(updatedFilter);

    const selectedStatuses = Object.keys(updatedFilter)
      .filter((key) => updatedFilter[key])
      .map((key) => statusMapping[key]);
    const filtered = data.filter((item) =>
      selectedStatuses.length > 0
        ? selectedStatuses.includes(item.status)
        : true
    );

    setFilteredData(filtered);
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
    if (pagination.pageSize !== paginationOptions.pageSize) {
      setPaginationOptions({
        ...paginationOptions,
        pageSize: pagination.pageSize,
      });
    }
  };

  const clearFilters = () => {
    setStatusFilter({
      ENABLED: false,
      PAUSED: false,
    });
    setSearchText('');
    setFilteredData(data);
  };

  const clearAll = () => {
    setSortedInfo({});
    setSelectedColumns(columns.map((column) => column.key));
  };

  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'impressions',
    });
  };

  const menufilter = (
    <Menu>
      <Menu.Item key="ENABLED">
        <Checkbox
          name="ENABLED"
          checked={statusFilter.ENABLED}
          onChange={handleCheckboxChange}
        >
          ENABLED
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="PAUSED">
        <Checkbox
          name="PAUSED"
          checked={statusFilter.PAUSED}
          onChange={handleCheckboxChange}
        >
          PAUSED
        </Checkbox>
      </Menu.Item>
    </Menu>
  );

  const filteredColumns = columns.filter((column) =>
    selectedColumns.includes(column.key)
  );

  const handleSearchInputChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    const filtered = data.filter((item) =>
      item.campaign_name.toLowerCase().includes(value)
    );

    setFilteredData(filtered);
  };
  const handleMenuClick = (clickedKey) => {
    const index = selectedColumns.indexOf(clickedKey);

    let updatedSelectedColumns = [];

    if (index === -1) {
      // Column was not selected before, add it to the selected columns
      updatedSelectedColumns = [...selectedColumns, clickedKey];
    } else {
      // Column was selected before, remove it from the selected columns
      updatedSelectedColumns = selectedColumns.filter(
        (key) => key !== clickedKey
      );
    }

    setSelectedColumns(updatedSelectedColumns);
  };
  const menu = (
    <Menu>
      {columns.map((column) => (
        <Menu.Item key={column.key} onClick={() => handleMenuClick(column.key)}>
          <Checkbox
            checked={selectedColumns.includes(column.key)}
            style={{ marginRight: '8px' }}
          />
          <span
            style={
              selectedColumns.includes(column.key) ? { fontWeight: 'bold' } : {}
            }
          >
            {column.title}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <div>
      
      <Space
        style={{
          marginBottom: 16,
        }}
      >
        <Dropdown overlay={menu} trigger={['click']}>
          <Button className="custom-button">
            {i18next.t('Select Metrcis ')} <DownOutlined />
          </Button>
        </Dropdown>
        <Button className="custom-button" onClick={setAgeSort}>
          {i18next.t('Sort Impressions')}
        </Button>
        <Button className="custom-button" onClick={clearFilters}>
          {i18next.t('Clear filters')}
        </Button>
        <Button className="custom-button" onClick={clearAll}>
          {i18next.t('Clear filters and sorters')}
        </Button>
      </Space>
      <div className="table-border">
        <div className="d-flex align-items-center" style={{ padding: '20px' }}>
          <Dropdown overlay={menufilter} trigger={['click']}>
            <Button
              className="custom-button"
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '22px',
              }}
            >
              <FeatherIcon
                icon="filter"
                size="16"
                style={{ marginRight: '8px' }}
              />
              {i18next.t('Filter')}
            </Button>
          </Dropdown>

          <div
            className="search-box"
            style={{
              flex: 1,
              maxWidth: '523px',
              position: 'relative',
              backgroundColor: '#F4F2FF',
              borderRadius: '6px',
              padding: '10px 15px',
            }}
          >
            <Input
              placeholder={i18next.t('Campaign Name')}
              value={searchText}
              onChange={handleSearchInputChange}
              className="custom-search-input"
            />
            <i
              className="bx bx-search-alt search-icon"
              style={{
                position: 'absolute',
                left: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                color: '#7a7a7a',
              }}
            />
          </div>
          {overview ? (
            <Link
              to="/google-dashboard"
              className="btn btn-primary btn-sm viewMetrics-button"
            >
              {i18next.t('View full metrics')}
            </Link>
          ) : (
            <></>
          )}
        </div>

        <Table
          columns={filteredColumns}
          dataSource={filteredData}
          onChange={handleChange}
          loading={loading}
          pagination={{
            ...paginationOptions,
            onChange: handleChange,
            showSizeChanger: true, // Enable the page size selector
            style: {
              backgroundColor: '#F4F2FF',
              padding: '10px 15px',
              marginTop: '2px',
              borderRadius: '6px',
              fontsize: '14px',
              fontweight: '700',
              color: '#6E6893',
            },
          }}
          headerCellStyle={{
            backgroundColor: '#FF0000', // Change this to your desired color
            color: '#333333', // Text color
          }}
        />
      </div>
    </div>
  );
};

export default AdTableSingleSearch;
