import GoogleAdsIcon from './GoogleAdsLogo.svg';
import FacebookAdsIcon from './MetaLogo.svg';
import GoogleAnalyticsIcon from './googleAnalytics.svg';
import FbSocialMediaIcon from './facebook.svg';
import InstagramLogo from './insta.svg';
export const logoMap = {
  'Google Ads': GoogleAdsIcon,
  'Facebook Ads': FacebookAdsIcon,
  'Google Analytics': GoogleAnalyticsIcon,
  'Facebook Social Media': FbSocialMediaIcon,
  'Instagram Social Media': InstagramLogo,
};
