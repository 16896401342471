import React, { useCallback, useEffect, useState } from 'react';
import ApolloClient from 'apollo-boost';
import urls from '../../routes/apiUrls';
import { getFacebookSocialMediaAccounts } from '../../queries/FacebookSocialMedia/queries';
import ActiveAccountBar from '../GoogleAnalytics/common/ActiveAccountBar';

const ApploCLI = new ApolloClient({
  uri: urls.facebookSocialMedia,
});
const FbSocialMediaActiveBar = () => {
  const [activeAccount, setActiveAccount] = useState(null);

  const fetchFacebookAccounts = useCallback(async () => {
    try {
      const { data } = await ApploCLI.query({
        query: getFacebookSocialMediaAccounts,
        context: {
          headers: {
            Authorization: `Bearer ${
              window.localStorage.getItem('token') || ''
            }`,
          },
        },
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
      });

      if (data.SocialMediaAccounts) {
        const active = data.SocialMediaAccounts.find(
          (account) => account.active
        );

        setActiveAccount(active || null);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);
  useEffect(() => {
    fetchFacebookAccounts();
  }, [fetchFacebookAccounts]);
  return (
    activeAccount && (
      <ActiveAccountBar
        accountName={activeAccount['name']}
        switchTabNumber={2}
      />
    )
  );
};

export default FbSocialMediaActiveBar;
