import { all, fork } from 'redux-saga/effects';
import LayoutSaga from './layout/saga';
import trackingStatusSaga from './aramexState/saga';
import prestashopSaga from './prestaShop/saga';
import woocommerceSaga from './woocommerce/saga';
import getKlaviyoSaga from './Klaviyo/saga';
import editProfileSaga from './auth/saga';
import memberSaga from './Member/saga';
import googleAnalyticsSaga from './googleAnalytics/saga';
import OPERATIONSaga from './reports/operations/saga';
import strategicSaga from './reports/strategic/saga';
import GoogleAdsSaga from './googleAds/saga';
import facebookAdsSaga from './facebookAds/saga';
import OverviewSaga from './reports/overview/saga';
import instagramSaga from './instagram/saga';
import facebookPageSaga from './facebookPage/saga';
import CurrencySaga from './currencyState/saga';

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(trackingStatusSaga),
    fork(prestashopSaga),
    fork(woocommerceSaga),
    fork(getKlaviyoSaga),
    fork(editProfileSaga),
    fork(memberSaga),
    fork(googleAnalyticsSaga),
    fork(OPERATIONSaga),
    fork(strategicSaga),
    fork(GoogleAdsSaga),
    fork(OverviewSaga),
    fork(facebookAdsSaga),
    fork(instagramSaga),
    fork(facebookPageSaga),
    fork(CurrencySaga),
  ]);
}
