import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { VectorMap } from 'react-jvectormap';
import './jquery-jvectormap.scss';
import geos from 'geos-major';
import { formatNumberWithSpaces } from '../common/HelperFunctions/utils';
const map = React.createRef(null);

const Vectormap = ({ data, color, type, width }) => {
  const [markers, setMarkers] = useState([]);
  const [value, setValue] = useState([]);
  console.log(data);
  useEffect(() => {
    const fetchMarkerData = async () => {
      try {
        const markerData = await Promise.all(
          data.map(async ({ countryId, newUsers }) => {
            try {
              const { latitude, longitude } = await geos.country(
                countryId.toLowerCase()
              );
              return {
                latLng: [latitude, longitude],
                name: `${formatNumberWithSpaces(newUsers)} Users`,
              };
            } catch (error) {
              console.error(
                `Error fetching geolocation for countryId ${countryId}:`,
                error
              );
              return null; // Handle this case appropriately
            }
          })
        );
        const values = data.map((item) => item.newUsers);
        setValue(values);
        // Filter out any null markers
        setMarkers(markerData.filter((marker) => marker !== null));
      } catch (error) {
        console.error('Error setting markers:', error);
      }
    };

    if (data.length > 0) {
      fetchMarkerData();
      console.log('fetchMarkerData', markers);
    }
  }, [data]);

  return (
    <div style={{ width: width, height: 500 }}>
      <VectorMap
        map={type}
        backgroundColor="transparent"
        ref={map}
        containerStyle={{
          width: '100%',
          height: '80%',
        }}
        regionStyle={{
          initial: {
            fill: color,
            stroke: 'none',
            'stroke-width': 0,
            'stroke-opacity': 0,
          },
          hover: {
            'fill-opacity': 0.8,
            cursor: 'pointer',
          },
          selected: {
            fill: '#2938bc', // Color of clicked country
          },
          selectedHover: {},
        }}
        containerClassName="map"
        markers={markers}
        markerStyle={{
          initial: {
            fill: '#FF925B',
            stroke: '#ffffff',
            'stroke-width': 2,
            cursor: 'pointer',
            transition: 'ease-in-out 0.3s',
          },
          hover: {
            fill: '#BD521C',
            stroke: '#ffffff',
            'stroke-width': 2,
            r: 9,
          },
        }}
        onMarkerTipShow={(event, label, index) => {
          label.css({
            'font-family': 'DM Sans, sans-serif',
            'font-size': '14px',
            'font-weight': 'bold',
            'font-color': 'black',
            cursor: 'pointer',
            color: 'black',
            borderColor: '#FED8C5',
            borderRadius: 4,
            borderWidth: 1,
            backgroundColor: '#FFFFFF',
          });
        }}
        onRegionTipShow={(event, label, index) => {
          label.css({
            'font-family': 'DM Sans, sans-serif',
            'font-size': '12px',
            'font-color': 'black',
            cursor: 'pointer',
            color: '#C4C4C4',
            borderColor: '#FED8C5',
            borderRadius: 4,
            borderWidth: 1,
            backgroundColor: '#FFFFFF',
          });
        }}
        series={{
          markers: [
            {
              attribute: 'r',
              normalizeFunction: undefined,
              scale: [4, 10],
              values: value,
            },
          ],
        }}
      />
    </div>
  );
};

Vectormap.propTypes = {
  color: PropTypes.string,
  value: PropTypes.any,
  width: PropTypes.any,
};

export default Vectormap;
