import React, { useState, useEffect, useCallback } from 'react';
import { Spin, Table, Switch, message, ConfigProvider, Input } from 'antd'; // Ant Design components
import { Card, CardBody, Col, Row, CardHeader } from 'reactstrap';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import ApolloClient from 'apollo-boost';
import {
  getFacebook,
  getFacebookPixels,
  toggleFbAccount,
  getFromFacebook,
  deletePlatformFacebook,
} from '../../../queries/FacebookAds/queries';
import urls from '../../../routes/apiUrls';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FacebookAdsIcon from '../../../assets/integrations_images/Meta_Platforms.svg';
import i18next from 'i18next';

const ApploCLI = new ApolloClient({
  uri: urls.facebook,
});

function FacebookAdsCard({ t }) {
  const [fbData, setFbData] = useState([]);
  const [filterStr, setFilterStr] = useState('');
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeAccounts, setActiveAccounts] = useState([]);
  const [inactiveAccounts, setInactiveAccounts] = useState([]);
  const [step, setStep] = useState(1);
  const [searchText, setSearchText] = useState('');

  const fetchData = useCallback(async () => {
    try {
      const { data } = await ApploCLI.query({
        query: getFacebook,
        context: {
          headers: {
            Authorization: `Bearer ${
              window.localStorage.getItem('token') || ''
            }`,
          },
        },
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
      });

      if (data.one) {
        setInfo(data.one);
        setStep(2);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  // Fetch Facebook Pixels
  const fetchFacebookPixels = useCallback(async () => {
    try {
      const { data } = await ApploCLI.query({
        query: getFacebookPixels,
        context: {
          headers: {
            Authorization: `Bearer ${
              window.localStorage.getItem('token') || ''
            }`,
          },
        },
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
      });

      if (data.adAccounts) {
        const active = data.adAccounts.filter((account) => account.active);
        const inactive = data.adAccounts.filter((account) => !account.active);
        setActiveAccounts(active);
        setInactiveAccounts(inactive);
        setFbData(data.adAccounts);

        if (active.length) {
     
          setStep(3);
        } else {
          setStep(2);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [info]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (info) fetchFacebookPixels();
  }, [info, fetchFacebookPixels]);

  const integrateFacebookAds = async (facebook_code) => {
    try {
      const { data } = await ApploCLI.mutate({
        mutation: getFromFacebook,
        context: {
          headers: {
            Authorization: `Bearer ${
              window.localStorage.getItem('token') || ''
            }`,
          },
        },
        variables: { code: facebook_code || '' },
        errorPolicy: 'all',
      });
      if (data.integration) {
        window.location.replace('/integrations');
        setStep(2);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const addConnection = () => {
    const FACEBOOK_APP_ID = 1188499042109576;
    const FACEBOOK_APP_REDIRECT_STATIC =
      'https://app.live-metrics.io/integrations';
    const connectUrl = `https://www.facebook.com/v18.0/dialog/oauth?client_id=${FACEBOOK_APP_ID}&redirect_uri=${FACEBOOK_APP_REDIRECT_STATIC}&scope=ads_read`;

    let windowObjectReference = window.open(
      connectUrl,
      '',
      'toolbar=no,menubar=no,width=700,height=700,top=100,left=100'
    );
    setLoading(true);

    const interval = setInterval(() => {
      try {
        if (windowObjectReference.closed) {
          setLoading(false);
          clearInterval(interval);
        } else if (
          windowObjectReference.location.hostname === 'app.live-metrics.io'
        ) {
          clearInterval(interval);
          const code = new URL(
            windowObjectReference.location.href
          ).searchParams.get('code');
          integrateFacebookAds(code);
          windowObjectReference.close();
          setStep(2);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    }, 1000);
  };

  const deleteConnection = () => {
    ApploCLI.mutate({
      mutation: deletePlatformFacebook,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
    }).then((data) => {
      if (data.data.delete.isDeleted) window.location.replace('/integrations');
      setInfo(null);
    });
  };

  // Handle toggling of accounts between active and inactive
  const handleToggle = async (fbAccountId, isCurrentlyActive) => {
    setLoading(true);
    try {
      const { data } = await ApploCLI.mutate({
        mutation: toggleFbAccount,
        variables: {
          platform_id: info.id,
          fb_act_id: fbAccountId,
        },
        context: {
          headers: {
            Authorization: window.localStorage.getItem('token')
              ? `Bearer ${window.localStorage.getItem('token')}`
              : '',
          },
        },
        errorPolicy: 'all',
      });

      if (data?.toggle) {
        const newState = data.toggle.active;

        // Display messages based on the new state
        if (newState) {
          message.success('Account deactivated successfully!');
        } else {
          message.success('Account activated successfully!');
        }

        // Update active and inactive accounts in state immediately
        if (newState) {
          // Move it to active and deactivate the other active account
          setActiveAccounts([data.toggle]); // Only one active account
          setInactiveAccounts((prev) => [
            ...prev,
            ...activeAccounts.filter(
              (account) => account.fb_act_id !== fbAccountId
            ),
          ]); // Move previously active to inactive
          setInactiveAccounts((prev) =>
            prev.filter((account) => account.fb_act_id !== fbAccountId)
          );
        } else {
          // If the account was active, move it to inactiveAccounts
          setInactiveAccounts((prev) => [...prev, data.toggle]);
          setActiveAccounts([]);
        }

        // Optionally, you can re-fetch the data to ensure the latest state from the backend
        await fetchFacebookPixels();
      } else {
        message.error('Failed to toggle the account. Please try again.');
      }
    } catch (err) {
      setLoading(false);
      message.error('An error occurred while toggling the account.');
      console.error('Toggle Error:', err);
    } finally {
      setLoading(false);
    }
  };

  // Define table columns with toggle action in the "Action" column
  const columns = [
    {
      title: 'STORE NAME',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <span className="table-cell-text">{text}</span>,
    },
    {
      title: 'ACTION',
      key: 'action',
      width: 140, // Set a fixed width for the ACTION column
      align: 'center', // Align the content of the ACTION column to the center
      render: (text, record) => (
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#5CCF5C', // Change the Switch's active background color to green
              colorTextQuaternary: '#6E6893', // Change the Switch's inactive background color to grey
              opacityLoading: 1, // opacity
            },
          }}
        >
          <Switch
            checked={record.active}
            disabled={!record.active && activeAccounts.length > 0} // Disable toggle for inactive accounts if there's an active account
            onChange={() => handleToggle(record.fb_act_id, record.active)}
            loading={loading}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </ConfigProvider>
      ),
    },
  ];

  // issue is filteredData is not updating and it's always empty until i type something in the search box
  const [filteredData, setFilteredData] = useState(inactiveAccounts);

  useEffect(() => {
    setFilteredData(inactiveAccounts);
  }, [inactiveAccounts]);

  const handleSearchInputChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    const filtered = inactiveAccounts.filter((item) =>
      item.name.toLowerCase().includes(value)
    );

    setFilteredData(filtered);
  };
  return (
    <div
      style={{ borderColor: '#D9D5EC', borderRadius: '3px', opacity: '1px' }}
    >
      <Row>
        <Col>
          <Card className="border text-secondary">
            <CardHeader style={{ borderBottom: 'none' }}>
              <img src={FacebookAdsIcon} alt="Google Ads" />
            </CardHeader>
            <CardBody>
              <div>
                {step === 1 && (
                  <>
                    <p style={{ fontSize: '16px', fontWeight: '400' }}>
                      {t('Connect your Meta Ads Store within 30 seconds')}
                    </p>
                    <button
                      onClick={addConnection}
                      className="btn btn-primary"
                      style={{
                        width: '100%',
                        backgroundColor: '#0080F9',
                        borderRadius: '6px',
                        fontSize: '17px',
                        fontWeight: '500',
                        border: 'none',
                      }}
                    >
                      {t('Connect')}
                    </button>
                  </>
                )}
                {step !== 1 && (
                  <>
                    <h5 className="" style={{ marginBottom: '20px', fontWeight: '600' }}>Choose Account</h5>
                    <div className="search-box"  style={{
                        flex: 1,
                        maxWidth: '523px',
                        position: 'relative',
                        backgroundColor: '#F4F2FF',
                        borderRadius: '6px',
                        padding: '10px 15px',
                        marginBottom: '10px',
                      }}>
                      <Input
                        placeholder={i18next.t('Convergen - P')}
                        value={searchText}
                        className="custom-search-input"
                        onChange={handleSearchInputChange}
                      />
                      <i
                        className="bx bx-search-alt search-icon"
                        style={{
                          position: 'absolute',
                          left: '10px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          color: '#7a7a7a',
                        }}
                      />
                    </div>

                    <Spin spinning={loading}>
                    {activeAccounts.length > 0 && (
                      <Table
                        bordered
                        columns={columns}
                        dataSource={activeAccounts}
                        rowKey="fb_act_id"
                        pagination={false}
                        style={{ marginBottom: '20px', marginTop: '20px' }}
                      />
                    )}
                      <Table
                        bordered
                        columns={columns}
                        dataSource={filteredData}
                        rowKey="fb_act_id"
                        pagination={{
                          pageSize: 5,
                          style: {
                            backgroundColor: '#F4F2FF',
                            padding: '10px 15px',
                            marginTop: '2px',
                            borderRadius: '6px',
                            fontsize: '14px',
                            fontweight: '700',
                            color: '#6E6893',
                          },
                        }}
                        headerCellStyle={{
                          backgroundColor: '#FF0000', // Change this to your desired color
                          color: '#333333', // Text color
                        }}
                      
                      />
                    </Spin>
                    {activeAccounts.length === 0 ? (
                      <div className="text-center">
                        <button
                          type="button"
                          className="btn btn-danger waves-effect waves-light"
                          style={{
                            width: '100%',
                            backgroundColor: '#F4F2FF',
                            borderRadius: '6px',
                            fontSize: '17px',
                            fontWeight: '500',
                            border: 'none',
                            marginTop: '10px',

                            color: '#6E6893',
                          }}
                          onClick={() => deleteConnection()}
                        >
                          {t('Delete Connect ')}
                        </button>
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

FacebookAdsCard.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(FacebookAdsCard));
