import React, { useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Row, Col, Container } from 'reactstrap';
import { Form } from 'react-final-form';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import CarouselRegister from '../AuthenticationInner/CarouselRegister';
import { useDispatch } from 'react-redux';
import { registerRequest, registerWithGoogleRequest } from '../../store/auth/actions';
import { useGoogleLogin } from '@react-oauth/google';
import Gooogleicon from '../../assets/images/google.png';

function Register() {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({ name: '', email: '' });

  const onSuccess = (response) => {
    if (response && response.access_token) {
      const tokenString = response.access_token;
      dispatch(registerWithGoogleRequest({ token: tokenString }));
    }
  };
  const onError = (error) => {
    if (error.error === 'popup_closed_by_user') {
      console.log('User closed the Google login popup.');
    }
  };
  const signUpWithGoogle = useGoogleLogin({
    onSuccess,
    onError,
    flow: 'implicit',
  });

  const verif_inputs = () => {
    let err = { name: '', email: '' };
    if (name.length < 3) {
      err.name = <p style={{ color: 'red' }}>First name required at least 3 characters</p>;
    }
    if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      err.email = <p style={{ color: 'red' }}>Invalid email address</p>;
    }
    setErrors(err);
    return err;
  };

  const onSubmit = () => {
    const errors = verif_inputs();
    if (!errors.name && !errors.email) {
      dispatch(registerRequest(email, name));
    }
  };

  const { handleSubmit } = useForm();

  return (
    <React.Fragment>
      <MetaTags>
        <title>Register | Convergen-Metrics - E-com SaaS</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            {/* Left Side */}
            <CarouselRegister />
            {/* Right Side */}
            <Col lg={4} md={5} className="col-xxl-6 d-flex justify-content-center align-items-center" style={{width:'50%'}}>
              <div className="auth-full-page-content align-items-center d-flex" style={{maxWidth: '560px', width: '100%', padding: '20px'}}>
                <div className="w-100">
                  <div className="auth-content my-auto">
                    <div className="text-left" style={{marginBottom:'30px'}}>
                      <h5 style={{fontSize:'28px', fontWeight:'800',color:'black'}}>Create an account</h5>
                      <span style={{fontSize:'16px', fontWeight:'400'}}>Get Your Free LiveMetrics Account Now.</span>
                    </div>
                    <Form onSubmit={handleSubmit(onSubmit)} className="custom-form mt-4 pt-2">
                      {({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                          <label htmlFor="email" className="form-label"style={{ fontSize: '1rem' }}>Email</label>
                          <input
                            type="email"
                            id="email"
                            placeholder="Enter email"
                            className="form-control"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            style={{ height: '60px', fontSize: '1rem', borderRadius: '8px', width: '100%' }}
                            />
                          {errors.email && errors.email}
                        </div>
                        
                        <div>
                          <label htmlFor="username" className="form-label"style={{ fontSize: '1rem' }}>Full Name</label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            required
                            placeholder="Enter Full Name"
                            className="form-control"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                            style={{ height: '60px', fontSize: '1rem', borderRadius: '8px', width: '100%', paddingRight: '45px' }}
                            />
                          {errors.name && errors.name}
                        </div>
                        
                        <div style={{marginBottom:'12px', marginTop:'32px'}}>
                          <button 
                              className="btn btn-auth w-100" 
                              type="submit" 
                              style={{ fontSize: '1rem', padding: '14px 0' }}
                          >
                            Create account
                          </button>
                        </div>
                      </form>
                      
                      
                      )}
                    </Form>
                    <button
  onClick={signUpWithGoogle}
  className="btn btn-light w-100 d-flex align-items-center justify-content-center"
  type="button"
  style={{
    height: '60px',
    color: '#4285F4',
    backgroundColor: '#d1e9ff',
    fontSize: '16px',
    fontWeight: '700',
    borderRadius: '8px',
  }}
>
  <img src={Gooogleicon} alt="Google Icon" height="20" className="me-3" />
  <span>Continue with Google</span>
</button>
                    <div className="text-center" style={{marginTop:'32px'}}>
                      <p className=" mb-0" style={{ fontSize: '1rem', color: 'gray' }}>
                        Already have an account? <Link to="/login" className="fw-semibold" style={{ fontSize: '1rem', color: '#4285F4' }}>Log In</Link>
                      </p>
                    </div>
                  </div>
                  {/* Footer stays at the bottom */}
        <footer className="text-center auth-footer" style={{
            color: 'gray',
            fontSize: '1rem',
            backgroundColor: 'transparent',
        }}>
          <p className="mb-0">
            © {new Date().getFullYear()} LiveMetrics. Crafted by Convergen
          </p>
        </footer>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Register;
