import React, { useState, useEffect } from 'react';
import { Row, Col, CardBody, Card, CardHeader, Spinner } from 'reactstrap';
import { Button, message } from 'antd';
import ExpensesTable from './ExpensesTable';
import BarChart from '../../GoogleAnalytics/common/BarChart';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import ApolloClient from "apollo-boost";
import urls from "../../../routes/apiUrls";
import { getInformations } from "../../../queries/Reports/PnLQuery";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import OverallCard from '../../OverviewDashboard/overallCardPieChart';
import CostsIcon from '../../../assets/images/CostsIcon.svg';
import ExportIcon from '../../../assets/images/ExportIcon.svg';
import CalenderIcon from '../../../assets/images/CalenderIcon.svg';
import ImportIcon from '../../../assets/images/ImportIcon.svg';
import GrossProfitTable from './GrossProfitTable';

const ApploCLI = new ApolloClient({
  uri: urls.report,
});

const dateFormat = (dateString) => {
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const date = new Date(dateString);
  return months[date.getMonth()];
};

function NProfitAndLoss(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [channel, setChannel] = useState("all");
  const [loadingState, setLoadingState] = useState(true);
  const [pnl, setPnl] = useState([]);
  const [newMinCosts, setNewMinCosts] = useState([]);

  const getData = (channel, selectedYear) => {
    setLoadingState(true);
    ApploCLI.query({
      query: getInformations,
      variables: { years: selectedYear + "", channel },
      context: { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } },
      fetchPolicy: "network-only",
    }).then((data) => {
      setLoadingState(false);
      setPnl(data.data.pnl);
      console.log("PNL Data:", data.data.pnl); // Log the full pnl data
setNewMinCosts(data.data.pnl && data.data.pnl.miscCosts ? data.data.pnl.miscCosts : []);

    });
  };

  useEffect(() => {
    getData(channel, selectedYear);
  }, [selectedYear, channel]);

  const colors = [
    "#1F77B4", "#FF7F0E", "#2CA02C", "#D62728", "#9467BD", "#8C564B",
    "#E377C2", "#7F7F7F", "#BCBD22", "#17BECF", "#AEC7E8", "#FFBB78"
  ];

  const finalData = pnl.map((item, index) => ({
    name: dateFormat(item.created),
    value: item.gross,
    itemStyle: {
      color: colors[index % colors.length],
    },
  }));

  const prepareSalesData = () => {
    return pnl.map(item => [dateFormat(item.created), parseFloat(item.sales)]);
  };

  const prepareOrdersAndUnitsData = () => {
    return pnl.map(item => [
      dateFormat(item.created),
      parseInt(item.orders),
      parseInt(item.units)
    ]);
  };

  const handleButtonClick = (e) => { };

  const handleMenuClick = (e) => {
    message.info('Year has been changed to ' + e.key);
    setSelectedYear(e.key);
  };

  const currentYear = new Date().getFullYear();

  const menuItems = [];
  for (let i = currentYear; i >= 2020; i--) {
    menuItems.push({
      label: ` ${i}  `,
      key: `${i}`,
    });
  }

  const menuProps = {
    items: menuItems,
    onClick: handleMenuClick,
  };

  return (
    <div className="page-content">
      <Breadcrumbs
        title={'Profit & Loss Report'}
        breadcrumbItem={'Profit & Loss'}
        pageHeading={'Reports'}
        image={CostsIcon}
      />      

      <Row>
        <Col xl={12}>
        <CardHeader className="d-flex justify-content-between align-items-center mt-4">
              <h2 style={{fontWeight:'400',color:'black',fontSize:'15px'}}>{props.t("Total Sales Over Time")}</h2>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button menu={menuProps} onClick={handleButtonClick} style={{ width: '106px', height: '53px', fontSize: '15px', color:'#0080F9', borderColor:'#0080F9',marginRight:'14px' }}>
                  <img src={CalenderIcon} alt="Export" style={{ marginRight: 10 }} />
                  {selectedYear}
                </Button>
                <Button type="primary" onClick={() => setModalVisible(true)} style={{ width: '156px', height: '53px', fontSize: '15px',marginRight:'14px' }}>
                  <img src={ExportIcon} alt="Export" style={{ marginRight: 10 }} />
                  Export PDF
                </Button>
                <Button onClick={() => setModalVisible(true)} style={{ width: '156px', height: '53px', fontSize: '15px',color:'#0080F9', borderColor:'#0080F9' }}>
                  <img src={ImportIcon} alt="Export" style={{ marginRight: 10 }} />
                  Import Data
                </Button>
              </div>
            </CardHeader>
          <CardBody>
            {loadingState ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner color="primary" />
              </div>
            ) : (
              <BarChart
                data={prepareSalesData()}
                dataKey="Sales"
                comparedData={[]}
                vertical={true}
                width="100%"
                color="#0080F9"
              />
            )}
          </CardBody>
        </Col>
      </Row>

      <Row>
        <Col xl={4}>
          <div className="d-flex align-items-center mb-4 mt-4">
            <OverallCard
              data={finalData}
              type="percentage"
              style={{ width: '500px', height: '500px' }}
            />
          </div>
        </Col>
        <Col xl={8}>
          <CardHeader className="d-flex justify-content-between align-items-center mt-4">
            <h2 style={{fontWeight:'400',color:'black',fontSize:'15px'}}>{props.t("Total Monthly Orders/Units")}</h2>
          </CardHeader>
          <CardBody>
            {loadingState ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner color="primary" />
              </div>
            ) : (
              <BarChart
                data={prepareOrdersAndUnitsData()}
                dataKey="Orders"
                comparedData={prepareOrdersAndUnitsData().map(item => [item[0], item[2]])}
                comparedDataKey="Units"
                vertical={true}
                width="100%"
                color="#F6B43B"
                comparedColor="#EC1A74"
              />
            )}
          </CardBody>
        </Col>
      </Row>

      <Row>
        <Col xl={12}>
        <CardHeader className="d-flex justify-content-between align-items-center mt-4 mb-3">
          <h2 style={{fontWeight:'bold',color:'black',fontSize:'30px'}}>{props.t(" Expenses ")}</h2>
        </CardHeader>
          <CardBody>
            {loadingState ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner color="primary" />
              </div>
            ) : (
<ExpensesTable pnl={pnl} t={props.t} additionalCosts={newMinCosts} />
            )}
          </CardBody>
        </Col>
      </Row>

      <Row style={{marginTop:'15px'}}>
        <Col xl={12}>
        <CardHeader className="d-flex justify-content-between align-items-center mt-4 mb-3">
          <h2 style={{fontWeight:'bold',color:'black',fontSize:'30px'}}>{props.t(" Profit ")}</h2>
        </CardHeader>
          <CardBody>
            {loadingState ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner color="primary" />
              </div>
            ) : (
<GrossProfitTable pnl={pnl} t={props.t} />    
       )}
          </CardBody>
        </Col>
      </Row>

      
    </div>
  );
}

NProfitAndLoss.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(NProfitAndLoss));
