import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Popover, ConfigProvider } from 'antd';

const Tooltip = ({ tooltip = '', formule, displayed }) => {
  const popoverContent = (
    <div
      style={{
        padding: '10px 15px', // Adjust padding
        borderRadius: '8px', // Rounded corners
        backgroundColor: '#FFFFFF',
        maxWidth: '250px',
        color: '#3A4155', // Text color
      }}
    >
      {/* Title with icon on the same line */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <InfoCircleOutlined
          style={{ fontSize: '24px', color: '#4A5568', marginRight: '10px' }}
        />
        <span style={{ fontSize: '14px', fontWeight: '700', color: '#4A5568' }}>
          {tooltip || 'No provided title'}
        </span>
      </div>

      {/* Supporting text starts directly under the title */}
      <div
        style={{
          fontSize: '10px',
          color: '#6C727F',
          marginTop: '5px',
          marginLeft: '34px', // Indent to match the start of the title (not the icon)
          lineHeight: '1.5',
        }}
      >
        {formule || 'This is a tip to help you accomplish a task.'}
      </div>
    </div>
  );

  return (
    <ConfigProvider
      theme={{
        token: {
          boxShadowSecondary: 'none',
          boxShadowPrimary: 'none',
          borderRadiusLG: '6px',
          fontFamily: 'DM Sans',
          fontWeight: '500',
        },
      }}
    >
      {tooltip.length > 0 ? (
        <Popover
          content={popoverContent}
          trigger="hover"
          overlayInnerStyle={{
            border: '1px solid #D9D5EC', 
            borderRadius: '6px',
            boxShadow: 'none', 
          }}
        >
          {displayed}
        </Popover>
      ) : (
        <span>{displayed}</span> 
      )}
    </ConfigProvider>
  );
};

export default Tooltip;
