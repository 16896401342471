import gql from 'graphql-tag';
const addCost = gql`
  mutation (
    $title: String!
    $category: String!
    $frequency: String!
    $startedDate: String!
    $endedDate: String!
    $price: Float!
  ) {
    add(
      title: $title
      category: $category
      frequency: $frequency
      startedDate: $startedDate
      endedDate: $endedDate
      price: $price
    ) {
      name
    }
  }
`;
const getCosts = gql`
  query {
    all {
      id
      name
      frequency
      category
      price
      endedDate
      activeDate
    }
  }
`;
const editCost = gql`
  mutation (
    $oldTitle: String!
    $title: String!
    $category: String!
    $frequency: String!
    $startedDate: String!
    $endedDate: String!
    $price: Float!
  ) {
    edit(
      oldTitle: $oldTitle
      title: $title
      category: $category
      frequency: $frequency
      startedDate: $startedDate
      endedDate: $endedDate
      price: $price
    ) {
      name
    }
  }
`;

const deleteSingleCost = gql`
  mutation ($id: String!) {
    delete(id: $id) {
      name
    }
  }
`;

const AddShippingCost = gql`
  mutation ($order: String!, $amount: Float!) {
    addShippingCost(order: $order, amount: $amount) {
      msg
      status
    }
  }
`;

export { addCost, getCosts, editCost, deleteSingleCost, AddShippingCost };
