import React from 'react';
import { Card, CardBody, Row } from 'reactstrap';
import i18next from 'i18next';
import { Bar } from 'react-chartjs-2';
import ReactApexChart from 'react-apexcharts';

const InstagramSocialMediaAge = ({ chartData, color }) => {
  const Colors = ['#EC1A74', '#0064E0', '#F6B43B', '#FF925B'];
  const chartSeries = chartData.datasets.map((item, index) => ({
    name: item.label,
    data: item.data,
    color: Colors[index],
  }));
  console.log('ahla ya jemaa', chartData);
  const chartOptions = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 2,
        columnWidth: '60%',
        borderRadiusApplication: 'top',
      },
    },
    grid: {
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        console.log(series);
        return `
        <div style="border: 2px solid ${
          Colors[seriesIndex]
        }; padding: 10px; border-radius: 3px; background-color: white; font-family: 'DM Sans', sans-serif;">
          <div style="font-size: 14px; color: black; margin-bottom: 5px; font-weight: 500;">${w.globals.labels[
            dataPointIndex
          ].toUpperCase()}</div>
          <div style="display: flex; align-items: center;">
            <span style="background-color: ${
              Colors[seriesIndex]
            }; width: 9px; height: 9px; display: inline-block; margin-right: 8px; border-radius: 2px"></span>
            <span style="font-size: 14px; color: #25213B; font-family:'DM Sans', sans-serif; font-weight: 500;">${
              chartSeries[seriesIndex].name
            }: </span>
            <span style="font-size: 16px; font-weight: bold; color: black; margin-left: 5px;">${
              series[seriesIndex][dataPointIndex]
            }</span>
          </div>
        </div>
      `;
      },
    },
    xaxis: {
      categories: chartData.labels,
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: 'black',
          fontWeight: 500,
          fontSize: '14px',
          fontFamily: 'Nunito sans, sans-serif',
        },
      },
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        align: 'center',
        style: {
          colors: 'black',
          fontWeight: 500,
          fontSize: '14px',
          fontFamily: 'Nunito sans, sans-serif',
        },
      },
    },
   
  };

  return (
    <ReactApexChart
      options={chartOptions}
      series={chartSeries}
      type="bar"
      width={'100%'}
      height={438}
    />
  );
};

export default InstagramSocialMediaAge;
