import { takeLatest, put, call } from 'redux-saga/effects';
import { getOverviewReport } from '../../../queries/Reports/Overviewreport';
import urls from '../../../routes/apiUrls';
import ApolloClient from 'apollo-boost';
import {
  GET_OVERVIEW_REPORT,
  getOverviewDataSuccess,
  getOverviewDataFailure,
} from './action';

const ApploCLI = new ApolloClient({
  uri: urls.report,
});

function* getOverview(action) {
  try {
    const variables = {
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
    };

    if (action.payload.compareStartDate) {
      variables.compareStartDate = action.payload.compareStartDate;
    }

    if (action.payload.compareEndDate) {
      variables.compareEndDate = action.payload.compareEndDate;
    }
    const { data } = yield call(ApploCLI.query, {
      query: getOverviewReport,
      variables,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
    if (data.generalreport) {
      yield put(getOverviewDataSuccess(data.generalreport));
    } else {
      yield put(getOverviewDataFailure('error'));
    }
  } catch (err) {
    yield put(getOverviewDataFailure(err));
  }
}

export default function* OverviewSaga() {
  yield takeLatest(GET_OVERVIEW_REPORT, getOverview);
}
