import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import {
    Card, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane
} from "reactstrap";
import classnames from "classnames";
import { withTranslation } from "react-i18next";
import { withRouter, useLocation } from "react-router-dom"; // Import useLocation
import Subscription from './subscription/Subscription';
import Profil from './Profil';
import Pricing from './Pricing';
import Members from './Members';
import SetttingsTab from './SettingsTab/SettingsTab';
import secureSection from '../../../MangeSecurity/MemberSecurity';

const Settings = (props) => {
    secureSection();

    const location = useLocation();  // Get location object
    const params = new URLSearchParams(location.search);
    const initialTab = params.get('tab') || '5';  // Get tab from URL, default to '5'

    const [settingTab, setSettingTab] = useState(initialTab);

    const toggleTab = (tab) => {
        if (settingTab !== tab) {
            setSettingTab(tab);
            localStorage.setItem('settingTab', tab);
            //seturl  with query tab 
            props.history.push(`/settings?tab=${tab}`);
        }
    };

    // Tab Components Mapping
    const tabComponents = {
        "1": <Subscription />,
        "2": <Profil />,
        "3": <Pricing />,
        "4": <Members />, 
        "5": <SetttingsTab />,       
    };

    const navLinkStyle = (active) => ({
        backgroundColor: "transparent" ,
        color: active ? "#0064E0" : "#25213B",
        padding: "10px 20px",
        minWidth: "150px",
        textAlign: "center",
        whiteSpace: "nowrap",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
        cursor: "pointer",
    });

    useEffect(() => {
        setSettingTab(params.get('tab') || '5');  // Update tab if the URL changes
    }, [location.search]);  // Listen for URL changes

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Integrations")} | LiveMetrics - E-com SaaS</title>
                </MetaTags>
                <Container fluid className=''>
                    <Row>
                     
                            <Card style={{ border: "none" }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Nav pills className="navtab-bg nav-justified custom-nav" style={{ justifyContent: 'center' }}>
                                        <NavItem style={{ marginRight: '10px' }}>
                                            <NavLink
                                                to="#"
                                                className={classnames({ active: settingTab === "1" })}
                                                onClick={() => toggleTab("1")}
                                                style={navLinkStyle(settingTab === "1")}
                                            >
                                                <i className="fas fa-credit-card" style={{ color: settingTab === '1' ? '#0064E0' : '#6c757d' }} />
                                                {props.t("Subscription")}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem style={{ marginRight: '10px' }}>
                                            <NavLink
                                                to="#"
                                                className={classnames({ active: settingTab === "2" })}
                                                onClick={() => toggleTab("2")}
                                                style={navLinkStyle(settingTab === "2")}
                                            >
                                                <i className="fas fa-user-alt" style={{ color: settingTab === '2' ? '#0064E0' : '#6c757d' }}/>
                                                {props.t("Profil")}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem style={{ marginRight: '10px' }}>
                                            <NavLink
                                                to="#"
                                                className={classnames({ active: settingTab === "3" })}
                                                onClick={() => toggleTab("3")}
                                                style={navLinkStyle(settingTab === "3")}
                                            >
                                                <i className="fas fa-dollar-sign" style={{ color: settingTab === '3' ? '#0064E0' : '#6c757d' }}/>
                                                {props.t("Pricing")}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem style={{ marginRight: '10px' }}>
                                            <NavLink
                                                to="#"
                                                className={classnames({ active: settingTab === "4" })}
                                                onClick={() => toggleTab("4")}
                                                style={navLinkStyle(settingTab === "4")}
                                            >
                                                <i className="fas fa-users" style={{ color: settingTab === '4' ? '#0064E0' : '#6c757d' }}/>
                                                {props.t("Members")}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                to="#"
                                                className={classnames({ active: settingTab === "5" })}
                                                onClick={() => toggleTab("5")}
                                                style={navLinkStyle(settingTab === "5")}
                                            >
                                                <i className="fas fa-cogs" style={{ color: settingTab === '5' ? '#0064E0' : '#6c757d' }}/> 
                                                {props.t("Settings")}
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>

                                <hr />
                                <Col lg={8} className="mx-auto">
                                <div className='mt-4'>
                                    <TabContent activeTab={settingTab}>
                                        {tabComponents[settingTab]}
                                    </TabContent>
                                </div>
                                </Col>
                            </Card>
                        
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(withTranslation()(Settings));
