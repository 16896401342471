import React, { useEffect, useState, useRef } from 'react';
import MetaTags from 'react-meta-tags';
import { format } from 'date-fns';
import { Spin } from 'antd';
import Select from 'react-select';
import axios from 'axios';
import { Form, Row, Col, Container, CardBody, Card } from 'reactstrap';
import { Carousel, CarouselItem, CarouselIndicators } from 'reactstrap';
//i18n
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FacebookAdsOverview from './FacebookAdsOverview';
import GoogleAdsOverview from './GoogleAdsOverview';
import FacebookpageOverview from './FacebookpageOverview';
import StoreOverview from './StoreOverview';
import { Button } from 'reactstrap';
// Form Editor
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  getComparedOverviewDataRequest,
  getOverviewDataRequest,
} from '../../store/reports/overview/action';
import GenralOverviewCard from './genralOverviewCard';
import MetaAdsFacebook from './Ads-creative-meta';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import logo from '../../assets/images/Live_Metrics_Logo.png';
import InstagrampageOverview from './InstagrampageOverview';
import { formatDate } from '../GoogleAnalytics/common/HelperFunctions/utils';

function OverviewDashboard(props) {
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.overview);
  const { dateRange } = useSelector((state) => state.dateRangeReducer);
  // end of  date range picker
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const [isChecked, setChecked] = useState(true);
  const [fbchecked, setfbChecked] = useState(true);
  const [fbpagechecked, setfbpageChecked] = useState(true);
  const [instagrampagechecked, setinstagrampageChecked] = useState(true);
  const [isGoogleAdsChecked, setGoogleAdsChecked] = useState(true);
  const [storechecked, setStoreChecked] = useState(true);
  const toggleOffcanvas = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };
  const handleGoogleAdsToggle = () => {
    setGoogleAdsChecked(!isGoogleAdsChecked);
  };
  const handleToggle = () => {
    setChecked(!isChecked);
  };
  const handlefbToggle = () => {
    setfbChecked(!fbchecked);
  };
  const handlefbpageToggle = () => {
    setfbpageChecked(!fbpagechecked);
  };
  const handleinstagrampageToggle = () => {
    setinstagrampageChecked(!instagrampagechecked);
  };
  const handelStoreToggle = () => {
    setStoreChecked(!storechecked);
  };
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    // Check localStorage for a previously selected image
    const storedImage = localStorage.getItem('selectedImage');
    if (storedImage) {
      setSelectedImage(storedImage);
    }
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const imageDataURL = event.target.result;

        // Save the selected image URL to localStorage
        localStorage.setItem('selectedImage', imageDataURL);

        setSelectedImage(imageDataURL);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChildClick = (valueFromChild) => {
    // This function receives the value from the child component
    setIsOffcanvasOpen(valueFromChild);
    // Process the received value or update the state as needed in the parent component
  };

  const [cards, setCards] = useState([
    {
      id: 1,
      component: storechecked ? (
        <StoreOverview data={data} handleClick={handleChildClick} />
      ) : null,
    },
    { id: 2, component: isChecked ? <GenralOverviewCard data={data} /> : null },
    {
      id: 3,
      component: fbchecked ? (
        <FacebookAdsOverview facebook={data.facebookMetrics} />
      ) : null,
    },
    {
      id: 4,
      component: MetaAdsFacebook ? (
        <MetaAdsFacebook dateRange={dateRange} />
      ) : null,
    },
    {
      id: 5,
      component: fbpagechecked ? (
        <FacebookpageOverview dateRange={dateRange} />
      ) : null,
    },
    {
      id: 6,
      component: isGoogleAdsChecked ? (
        <GoogleAdsOverview google={data.GoogleMetrics} dateRange={dateRange} />
      ) : null,
    },
    {
      id: 7,
      component: instagrampagechecked ? (
        <InstagrampageOverview dateRange={dateRange} />
      ) : null,
    },

    // Add other components similarly
  ]);
  useEffect(() => {
    // Update cards based on changes in data variables
    setCards([
      {
        id: 1,
        component: storechecked ? (
          <StoreOverview data={data} handleClick={handleChildClick} />
        ) : null,
      },
      {
        id: 2,
        component: isChecked ? <GenralOverviewCard data={data} /> : null,
      },
      {
        id: 3,
        component: fbchecked ? (
          <FacebookAdsOverview facebook={data.facebookMetrics} />
        ) : null,
      },
      {
        id: 4,
        component: MetaAdsFacebook ? (
          <MetaAdsFacebook dateRange={dateRange} />
        ) : null,
      },
      {
        id: 5,
        component: fbpagechecked ? (
          <FacebookpageOverview
            dateRange={dateRange}
            facebookpage={data.facebookpage}
          />
        ) : null,
      },
      {
        id: 6,
        component: isGoogleAdsChecked ? (
          <GoogleAdsOverview
            google={data.GoogleMetrics}
            dateRange={dateRange}
          />
        ) : null,
      },
      {
        id: 7,
        component: instagrampagechecked ? (
          <InstagrampageOverview dateRange={dateRange} />
        ) : null,
      },

      // Add other components similarly
    ]);
  }, [
    isChecked,
    fbchecked,
    fbpagechecked,
    isGoogleAdsChecked,
    data,
    dateRange,
    storechecked,
    instagrampagechecked,
  ]);

  useEffect(() => {
    if (dateRange.selection1.startDate && dateRange.selection1.endDate) {
      if (dateRange.selection2.startDate && dateRange.selection2.endDate) {
        console.log('aefuaebfoaiefnbaliebf');
        dispatch(
          getComparedOverviewDataRequest(
            formatDate(dateRange.selection1.startDate),
            formatDate(dateRange.selection1.endDate),
            formatDate(dateRange.selection2.startDate),
            formatDate(dateRange.selection2.endDate)
          )
        );
      } else
        dispatch(
          getOverviewDataRequest(
            formatDate(dateRange.selection1.startDate),
            formatDate(dateRange.selection1.endDate)
          )
        );
    }
  }, [dateRange]);

  const items = [
    {
      src: 'banner-image1.png', // Replace with your image path
      altText: 'Slide 1',
      caption: 'Here we promote new features and tutorials',
    },
    {
      src: 'banner-image2.png', // Replace with your image path
      altText: 'Slide 2',
      caption: 'Learn about the latest updates',
    },
    {
      src: 'banner-image3.png', // Replace with your image path
      altText: 'Slide 3',
      caption: 'Get insights with our new tools',
    },
  ];

  const BannerCarousel = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const itemsLength = items.length;

    const next = () => {
      if (animating) return;
      const nextIndex = activeIndex === itemsLength - 1 ? 0 : activeIndex + 1;
      setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
      if (animating) return;
      setActiveIndex(newIndex);
    };

    useEffect(() => {
      const interval = setInterval(() => {
        next();
      }, 3000); // Change slides every 3 seconds

      return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [activeIndex, animating]);

    const slides = items.map((item) => {
      return (
        <CarouselItem
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
          key={item.src}
        >
          <img
            src={item.src}
            alt={item.altText}
            style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
          />
          <div
            className="carousel-caption"
            style={{
              position: 'absolute',
              bottom: '20px', // Adjust as needed
              left: '20px', // Adjust as needed
              textAlign: 'left',
              color: 'white',
            }}
          >
            <h5
              style={{
                fontSize: '24px', // Adjust to match the desired size
                fontWeight: 'bold',
                marginBottom: '30px', // Space between the caption and the button
                color: 'white', // Adjust to match the desired color
                width: '300px', // Adjust as needed
              }}
            >
              {item.caption}
            </h5>
            <Button
              color="light"
              style={{
                fontSize: '11px', // Adjust to match the desired size
                fontWeight: 'bold',
                color: '#0080F9', // Adjust to match the desired color
                backgroundColor: 'white', // Adjust to match the desired color
                padding: '8px 16px', // Adjust as needed
                borderRadius: '6px', // Adjust as needed
                width: '164px', // Adjust as needed
                height: '41.26px', // Adjust as needed
              }}
              className="mt-2"
            >
              View full metrics
            </Button>
          </div>
        </CarouselItem>
      );
    });

    return (
      <Carousel activeIndex={activeIndex} interval={false}>
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {slides}
      </Carousel>
    );
  };
  const handleDrag = (startIndex, endIndex) => {
    const result = Array.from(cards);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    setCards(result);
  };
  const [downloadloading, setdownloadloading] = useState(false);
  const exportToPDF = async () => {
    try {
      setdownloadloading(true);
      const capture = document.querySelector('.print');
      const canvas = await html2canvas(capture, { useCORS: true, scale: 1 }); // Adjust scale for higher resolution
      const imgData = canvas.toDataURL('image/png', 9.0); // Adjust quality
      const doc = new jsPDF({
        orientation: 'portrait', // 'portrait' or 'landscape'
        unit: 'mm',
        format: [canvas.width, canvas.height], // A4 size in millimeters (default)
      });

      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      const padding = 20; // Adjust this value to set the padding in millimeters
      // Add padding to X and Y coordinates
      doc.addImage(
        imgData,
        'PNG',
        1 + padding,
        padding,
        componentWidth - 2 * padding,
        componentHeight - 2 * padding
      );
      // // Customize the appearance of the PDF
      // doc.setLineWidth(0.5); // Set line width for borders
      // doc.setDrawColor(103, 137, 242); // Set border color to black
      // doc.rect(1, 1, componentWidth - 1, componentHeight - 1, 'S'); // Add a border around the content
      doc.save('Rapport-Instagram-Ads.pdf');
    } catch (error) {
      console.error('Error exporting to PDF:', error);
    } finally {
      setdownloadloading(false);
    }
  };

  const loadingSection = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90vh',
      }}
    >
      <Spin size="large" />
    </div>
  );

  return (
    <React.Fragment>
      <div className="page-content print">
        <MetaTags>
          <title>
            {props.t('Overview Dashboard')} | LiveMetrics - E-com SaaS
          </title>
        </MetaTags>
        <Container fluid className="p-4">
          <Card className="tamour">
            <CardBody>
              {/* Wrap the entire card body with a label to make it clickable */}
              <label
                htmlFor="imageUpload"
                style={{
                  cursor: 'pointer',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <input
                  type="file"
                  id="imageUpload"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={handleImageChange}
                />
                {selectedImage ? (
                  <img
                    src={selectedImage}
                    alt="Selected"
                    style={{
                      maxWidth: '100%',
                      maxHeight: '80px',
                      display: 'block',
                    }}
                  />
                ) : (
                  <img
                    src={logo} // Use your logo or any placeholder image
                    alt="Upload"
                    style={{
                      maxWidth: '200px',
                      maxHeight: '80px',
                      display: 'block',
                    }}
                  />
                )}
              </label>
            </CardBody>
          </Card>

          {loading ? (
            loadingSection()
          ) : (
            <Row>
              <Col lg={12}>
                {cards.map(
                  (card, index) =>
                    card.component && ( // Check if the component exists before rendering
                      <div key={card.id}>{card.component}</div>
                    )
                )}
              </Col>
            </Row>
          )}

          {/* Invoice Note */}
          <Row className="mt-5">
            <div
              className="mb-2"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div className="col">
                <h5 className="card-title d-flex align-items-center">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_73_5033)">
                      <g clipPath="url(#clip1_73_5033)">
                        <path
                          d="M20.333 24H4.33301C2.13301 24 0.333008 22.2 0.333008 20V4C0.333008 1.8 2.13301 0 4.33301 0H20.333C22.533 0 24.333 1.8 24.333 4V20C24.333 22.2 22.533 24 20.333 24Z"
                          fill="#0080F9"
                        />
                        <path
                          d="M19.3329 2C18.5329 2 17.7329 2.3 17.2329 2.9L5.03291 15C4.93291 15.1 4.83291 15.2 4.83291 15.3L2.43291 20.6C2.23291 21 2.33291 21.4 2.63291 21.7C2.83291 21.9 3.03291 22 3.33291 22C3.43291 22 3.63291 22 3.73291 21.9L9.03291 19.5C9.13291 19.5 9.23291 19.4 9.33291 19.3L21.4329 7.1C22.0329 6.6 22.3329 5.8 22.3329 5C22.3329 3.3 21.0329 2 19.3329 2ZM9.23291 16.5L7.83291 15.1L15.9329 7L17.3329 8.4L9.23291 16.5ZM6.53291 16.6L7.83291 17.9L5.43291 19L6.53291 16.6ZM20.0329 5.7L18.7329 7L17.3329 5.6L18.6329 4.3C18.8329 4.1 19.0329 4 19.3329 4C19.9329 4 20.3329 4.4 20.3329 5C20.3329 5.3 20.2329 5.5 20.0329 5.7Z"
                          fill="white"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_73_5033">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="translate(0.333008)"
                        />
                      </clipPath>
                      <clipPath id="clip1_73_5033">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="translate(0.333008)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <h6
                    className="card-title ms-2"
                    style={{ fontWeight: '700', color: '#000000' }}
                  >
                    {props.t('Recommandation')}
                  </h6>
                </h5>
              </div>
            </div>
            <Row>
              <Col md={8}>
                <Form method="post">
                  <Editor
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                  />
                </Form>
              </Col>

              <Col md={4}>
                <div
                  className="banner-container"
                  style={{
                    backgroundColor: '#0080F9',
                    borderRadius: '8px',
                    padding: '10px',
                    color: 'white',
                  }}
                >
                  <BannerCarousel />
                </div>
              </Col>
            </Row>
          </Row>
        </Container>
        <button
          onClick={exportToPDF}
          className={`btn btn-primary w-md ${loading ? 'disabled' : ''}`}
          disabled={loading}
          fontSize="13px"
          style={{
            width: '212px',
            height: '53px',
            borderRadius: '6px',
            backgroundColor: '#0080F9',
            borderColor: '#0080F9',
            marginBottom: '10px',
          }}
        >
          {downloadloading ? (
            <>
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 text-white" />{' '}
              Loading
            </>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: '100%' }}
            >
              <svg
                width="24"
                height="23"
                viewBox="0 0 24 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_73_5041)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.25168 0.333008H17.748C20.7172 0.333008 23.1467 2.76131 23.1467 5.7317V17.6007C23.1467 20.5699 20.7172 22.9994 17.748 22.9994H6.25168C3.28252 22.9994 0.853027 20.5699 0.853027 17.6007V5.7317C0.853027 2.76131 3.28252 0.333008 6.25168 0.333008Z"
                    fill="white"
                  />
                  <path
                    d="M7.65596 17.1918H16.3438C16.4542 17.1918 16.5433 17.2809 16.5433 17.3913V18.5006C16.5433 18.6111 16.4542 18.7002 16.3438 18.7002H7.65596C7.54551 18.7002 7.45643 18.6111 7.45643 18.5006V17.3913C7.45639 17.2809 7.54551 17.1918 7.65596 17.1918ZM11.8587 15.9218L7.58566 11.6476C7.50912 11.5723 7.4602 11.4781 7.4401 11.3778C7.42128 11.2799 7.43005 11.1757 7.47144 11.0753C7.51288 10.9762 7.58061 10.8946 7.66469 10.8382C7.75004 10.7817 7.8504 10.7503 7.95709 10.7503H9.97248V4.83088C9.97248 4.72046 10.0616 4.63135 10.172 4.63135H13.8276C13.9368 4.63135 14.0271 4.72046 14.0271 4.83088V10.7503H16.0425C16.1491 10.7503 16.2496 10.7817 16.3349 10.8382C16.4189 10.8947 16.4867 10.9762 16.5281 11.0753C16.5695 11.1757 16.5783 11.2799 16.5595 11.3778C16.5394 11.4782 16.4905 11.5723 16.4139 11.6476L12.1409 15.9218C12.0632 15.9996 11.9365 15.9996 11.8587 15.9218Z"
                    fill="#0080F9"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_73_5041">
                    <rect
                      width="22.6667"
                      height="22.6667"
                      fill="white"
                      transform="translate(0.666504 0.333008)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <span className="ms-2">{props.t('Download')}</span>
            </div>
          )}
        </button>
      </div>
    </React.Fragment>
  );
}
OverviewDashboard.propTypes = {
  t: PropTypes.any,
};
export default withRouter(withTranslation()(OverviewDashboard));
