import gql from 'graphql-tag';

const getWooStore = gql`query{
    get{
        id,
        url,
        connectionstate, 
    }
}
`
const integrateWoo = gql`mutation($key:String!, $secret:String!, $url:String!){
    integration(key:$key,secret:$secret,url:$url){
        url,
        accessToken,
        createdAt
    }
}
`
const deleteIntegration = gql`mutation{
    delete{
        isDeleted
    }
}
`

export { getWooStore, integrateWoo, deleteIntegration};

