import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, BreadcrumbItem } from 'reactstrap';

const Breadcrumb = (props) => {
  return (
    <div className="page-title-box d-flex align-items-center justify-content-between mb-4 ">
      <div
        className="page-title-left d-flex align-items-center"
        // style={{ marginLeft: '20px' }}
      >
       
       { props.image &&  ( <img  
          src={props.image} // Add your image path here
          alt="Logo"
          className="page-title-logo"
          style={{ width: '30px', height: '30px' }} // Customize the size and style as needed
        />)}
        <span className="ms-2 fw-bold">{props.title}</span>
      </div>
      {props.pageHeading && props.breadcrumbItem && (
        <div className="page-title-right" style={{ marginRight: '60px' }}>
          <ol className="breadcrumb m-0">
            <BreadcrumbItem>
              <Link to="#">{props.pageHeading}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>
              <Link to="#">{props.breadcrumbItem}</Link>
            </BreadcrumbItem>
          </ol>
        </div>
      )}
    </div>
  );
};

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
};

export default Breadcrumb;
