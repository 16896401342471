import React from 'react';
import {
  formatNumberWithSpaces,
  getTotal,
} from '../GoogleAnalytics/common/HelperFunctions/utils';

import GoogleAdsCard from '../GoogleDashboard/Google Ads Overview/GoogleAdsCard/GoogleAdsCard';


const InstagramSocialMediaCards = ({ secCards }) => {
  console.log(secCards);

  const colors = [
    {
      lineColor: '#FF553F',
      fillColor: [
        {
          offset: 0,
          color: ' rgba(255, 98, 65, 0.44)',
        },
      
        {
          offset: 1,
          color: 'rgba(255,211,84,0.44)',
        },
      ],
    },
    {
      lineColor: '#F6B43B',
      fillColor: [
        {
          offset: 0,
          color: ' #F6B43B',
        },

        {
          offset: 1,
          color: '#D9D9D9',
        },
      ],
    },
  ];
  const renderContent = (item) => {
    return formatNumberWithSpaces(getTotal(item.data));
  };

  const finalData = secCards.map((item) => {
    if (item.compareData && item.compareData.length > 0) {
      return {
        name: item.name,
        totalValue: renderContent(item, 'data'),
        percentage: (
          ((getTotal(item.data) - getTotal(item.compareData)) /
            getTotal(item.compareData)) *
          100
        ).toFixed(2),
        series: [
          { name: item.name, data: item.data },
          { name: `compare ${item.name}`, data: item.compareData },
        ],
      };
    } else
      return {
        name: item.name,
        totalValue: renderContent(item, 'data'),
        series: [{ name: item.name, data: item.data }],
      };
  });
  return (
    <>
      {finalData.map((item, index) => (
        <GoogleAdsCard item={item} key={index} colors={colors} />
      ))}
    </>
  );
};

export default InstagramSocialMediaCards;
