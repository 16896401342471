import React, { useEffect, useState, useRef } from 'react';
import { Col, Row } from 'reactstrap';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { alert4 } from '../../../pages/Alerts/NotLinkedAccountAlert';
import GaAudience from '../gaAudience';
import { useDispatch, useSelector } from 'react-redux';
import Vectormap from './Vectormap';
import {
  getGAUsersCompraisonRequest,
  getGAUsersRequest,
} from '../../../store/googleAnalytics/actions';

import { formatDate } from '../common/HelperFunctions/utils';
import GAActiveAccountBar from '../common/GAActiveAccountBar';
import { logoMap } from '../../../assets/integrations_images/logoMap';

import GoogleAdsChart from '../../GoogleDashboard/Google Ads Overview/GoogleAdsChart/GoogleAdsChart';
import GACardnew from '../common/GACardnew';
import { Spin } from 'antd';

function GaUsers() {
  const { loading, gaUsers } = useSelector((state) => state.googleAnalytics);

  const [usersDataDashboard, setUsersDataDashboard] = useState([]);
  const [usersComapredDataDashboard, setUsersComapredDataDashboard] = useState(
    []
  );
  const [userEngagement, setUserEngagement] = useState([]);
  const [screenPageViewsPerUser, setScreenPageViewsPerUser] = useState([]);
  const [users, setUsers] = useState([]);
  const [newUsers, setNewUsers] = useState([]);
  const [comparedUserEngagement, setComparedUserEngagement] = useState([]);
  const [comparedScreenPageViewsPerUser, setComparedScreenPageViewsPerUser] =
    useState([]);
  const [comparedUsers, setComparedUsers] = useState([]);
  const [comparedNewUsers, setComparedNewUsers] = useState([]);
  const [mapData, setMapData] = useState([]);

  const aggregateUsersByCountry = (gausers) => {
    if (!Array.isArray(gausers)) return [];

    const userCounts = gausers.reduce((acc, user) => {
      if (user && Array.isArray(user.newUsersByCountry)) {
        user.newUsersByCountry.forEach((countryData) => {
          if (countryData && countryData.countryId) {
            acc[countryData.countryId] =
              (acc[countryData.countryId] || 0) + countryData.newUsers;
          }
        });
      }
      return acc;
    }, {});

    return Object.entries(userCounts)
      .map(([countryId, newUsers]) => ({ countryId, newUsers }))
      .sort((a, b) => b.newUsers - a.newUsers)
      .slice(0, 10); // Get the top 20 countries by new users
  };
  const dispatch = useDispatch();
  const { dateRange } = useSelector((state) => state.dateRangeReducer);
  useEffect(() => {
    let isSubscribed = true;

    const newStartDate = formatDate(dateRange.selection1.startDate);
    const newEndDate = formatDate(dateRange.selection1.endDate);

    if (!newStartDate || !newEndDate) {
      console.error('Invalid date format');
      return;
    }

    if (dateRange.selection2.startDate && dateRange.selection2.startDate) {
      const compareStartDateFormatted = formatDate(
        dateRange.selection2.startDate
      );
      const compareEndDateFormatted = formatDate(dateRange.selection2.endDate);

      if (!compareStartDateFormatted || !compareEndDateFormatted) {
        console.error('Invalid comparison date format');
        return;
      }

      if (isSubscribed) {
        dispatch(
          getGAUsersCompraisonRequest(
            newStartDate,
            newEndDate,
            compareStartDateFormatted,
            compareEndDateFormatted
          )
        );
      }
    } else if (isSubscribed) {
      dispatch(getGAUsersRequest(newStartDate, newEndDate));
    }

    // Effect cleanup and checking for subscription status
    return () => {
      isSubscribed = false;
    };
  }, [dateRange]);

  useEffect(() => {
    if (gaUsers) {
      if (gaUsers.length > 0) {
        const sortedData = gaUsers[0].sort(
          (a, b) => new Date(a.day) - new Date(b.day)
        );

        setUsersDataDashboard(sortedData);

        const topCountries = aggregateUsersByCountry(sortedData);
        setMapData(topCountries);
        setUsersComapredDataDashboard([]);
        if (gaUsers.length > 1) {
          const sortedComparedData = gaUsers[1].sort(
            (a, b) => new Date(a.day) - new Date(b.day)
          );

          setUsersComapredDataDashboard(sortedComparedData);
        }
      }
    }
  }, [gaUsers]);

  useEffect(() => {
    let isSubscribed = true;
    var TUserEngagement = [];
    var TScreenPageViewsPerUser = [];
    var Tusers = [];
    var TnewUsers = [];
    if (usersDataDashboard.length !== 0) {
      usersDataDashboard.forEach((elem) => {
        // Calculate user engagement per user
        const users = elem.users ? elem.users : 0;
        const userEngagement = elem.userEngagementDuration
          ? elem.userEngagementDuration
          : 0;
        const engagementPerUser = users > 0 ? userEngagement / users : 0;

        TUserEngagement.push(engagementPerUser);
        TScreenPageViewsPerUser.push(
          elem.screenPageViewsPerUser ? elem.screenPageViewsPerUser : 0
        );
        Tusers.push(users);
        TnewUsers.push(elem.newUsers ? elem.newUsers : 0);
      });
    }
    setUserEngagement(TUserEngagement);
    setScreenPageViewsPerUser(TScreenPageViewsPerUser);
    setUsers(Tusers);
    setNewUsers(TnewUsers);

    return () => (isSubscribed = false);
  }, [usersDataDashboard]);

  useEffect(() => {
    let isSubscribed = true;
    var TComparedUserEngagement = [];
    var TComparedScreenPageViewsPerUser = [];
    var TComparedusers = [];
    var TComparednewUsers = [];
    if (usersComapredDataDashboard.length !== 0) {
      usersComapredDataDashboard.forEach((elem) => {
        // Calculate user engagement per user
        const users = elem.users ? elem.users : 0;
        const userEngagement = elem.userEngagementDuration
          ? elem.userEngagementDuration
          : 0;
        const engagementPerUser = users > 0 ? userEngagement / users : 0;

        TComparedUserEngagement.push(engagementPerUser);
        TComparedScreenPageViewsPerUser.push(
          elem.screenPageViewsPerUser ? elem.screenPageViewsPerUser : 0
        );
        TComparedusers.push(users);
        TComparednewUsers.push(elem.newUsers ? elem.newUsers : 0);
      });
    }
    setComparedUserEngagement(TComparedUserEngagement);
    setComparedScreenPageViewsPerUser(TComparedScreenPageViewsPerUser);
    setComparedUsers(TComparedusers);
    setComparedNewUsers(TComparednewUsers);

    return () => (isSubscribed = false);
  }, [usersComapredDataDashboard]);

  const [myCards, setMyCards] = useState([
    {
      id: 1,
      name: 'Users',
      data: users,
      compareData: comparedUsers,
      tooltip: 'The number of users',
      formule: '',
    },
    {
      id: 2,
      name: 'New Users',
      data: newUsers,
      compareData: comparedNewUsers,
      tooltip: 'The number of new users',
      formule: '',
    },
    {
      id: 3,
      name: 'User Engagement',
      data: userEngagement,
      compareData: comparedUserEngagement,
      tooltip: 'The average time spent on the app per user',
      formule: 'User Engagement = Total Time Spent / Users',
    },
    {
      id: 4,
      name: 'screenpage views per user',
      data: screenPageViewsPerUser,
      compareData: comparedScreenPageViewsPerUser,
      tooltip: 'The average number of screen views per user',
      formule: 'Screen Page Views Per User = Screen Page Views / Users',
    },
  ]);

  useEffect(() => {
    setMyCards([
      {
        id: 1,
        name: 'Users',
        data: users,
        compareData: comparedUsers,
        tooltip: 'The number of users',
        formule: '',
      },
      {
        id: 2,
        name: 'New Users',
        data: newUsers,
        compareData: comparedNewUsers,
        tooltip: 'The number of new users',
        formule: '',
      },
      {
        id: 3,
        name: 'User Engagement',
        data: userEngagement,
        compareData: comparedUserEngagement,
        tooltip: 'The average time spent on the app per user',
        formule: 'User Engagement = Total Time Spent / Users',
      },
      {
        id: 4,
        name: 'screenpage views per user',
        data: screenPageViewsPerUser,
        compareData: comparedScreenPageViewsPerUser,
        tooltip: 'The average number of screen views per user',
        formule: 'Screen Page Views Per User = Screen Page Views / Users',
      },
    ]);
  }, [
    users,
    newUsers,
    userEngagement,
    comparedUsers,
    comparedNewUsers,
    comparedUserEngagement,
    screenPageViewsPerUser,
    comparedScreenPageViewsPerUser,
  ]);

  // options of chart

  const series = [
    {
      name: 'users',
      data: users,
      compareData: comparedUsers,
      value: 'Users',
    },
    {
      name: 'user Engagement',
      data: userEngagement,
      compareData: comparedUserEngagement,
      value: 'User Engagement',
    },
    {
      name: 'new Users',
      data: newUsers,
      compareData: comparedNewUsers,
      value: 'New Users',
    },
    {
      name: 'screen Page Views Per User',
      data: screenPageViewsPerUser,
      compareData: comparedScreenPageViewsPerUser,
      value: 'Screen Page Views Per User',
    },
  ];

  const colors = [
    {
      lineColor: '#FE915B',
      fillColor: [
        {
          offset: 0,
          color: '#FE915B',
        },
        {
          offset: 0.47,
          color: 'rgba(254, 145, 91, 0.7)',
        },
        {
          offset: 1,
          color: 'rgba(254, 145, 91, 0.1)',
        },
      ],
    },
    {
      lineColor: '#F6B43B',
      fillColor: [
        {
          offset: 0,
          color: ' #F6B43B',
        },

        {
          offset: 1,
          color: '#D9D9D9',
        },
      ],
    },
  ];

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Spin spinning={loading} size="large">
          <GAActiveAccountBar />
          <Breadcrumbs
            title="Google Analytics Users Overview"
            breadcrumbItem="Users"
            pageHeading="Google Analytics Dashboard"
            image={logoMap['Google Analytics']}
          />

          <Row>
            <Col xl={12}>
              <GoogleAdsChart series={series} colors={colors} />
            </Col>
          </Row>
          <Row className=" justify-content-center my-5">
            <GACardnew data={myCards} colors={colors} />
          </Row>
          <Breadcrumbs
            title="New Users by Country"
            image={logoMap['Google Analytics']}
          />
          <div id="world-map-markers" className="vector-map-height mt-5">
            <Vectormap
              type="world_mill"
              width="500"
              color="#FED8C5"
              data={mapData}
            />
          </div>
          <Breadcrumbs title="Audience" image={logoMap['Google Analytics']} />
          <GaAudience />
        </Spin>
      </div>
    </div>
  );
}

export default GaUsers;
