import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { logoMap } from '../../assets/integrations_images/logoMap';
import Tooltip from '../GoogleDashboard/Google Ads Overview/GoogleAdsCard/Tooltip';
import { tooltipMap } from '../../locales/tooltip/tooltip';

const GeneralOverviewHorizontalCard = (props) => {
  return (
    <Col>
      <Card
        className="card-h-100"
        style={{ border: '1px solid #D3E3FD', borderRadius: '6px' }}
      >
        <CardBody>
          <div className="my-0 text-primary d-flex justify-content-between align-items-center">
            <Tooltip
              displayed={
                <span
                  className="my-0 text-primary"
                  style={{
                    fontWeight: '700',
                    fontSize: '14px',
                    color: '#0044BD',
                  }}
                >
                  {props.t(props.item.title)}
                </span>
              }
              tooltip={tooltipMap[props.item.title]?.tooltip}
              formule={tooltipMap[props.item.title]?.formula}
            />
            <div>
              <img
                className="img-intgration-icon"
                src={logoMap['Google Ads']}
                alt="Google Ads"
              />
              <img
                className="img-intgration-icon"
                src={logoMap['Facebook Ads']}
                alt="Facebook Ads"
              />
            </div>
          </div>
          <Row className="align-items-center">
            <Col xs={12}>
              <div className="mt-3">
                <h3
                  style={{
                    fontWeight: '700',
                    color: '#000000',
                    fontSize: '24px',
                  }}
                >
                  {props.item.data}
                </h3>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default GeneralOverviewHorizontalCard;
