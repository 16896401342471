import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Card, CardBody, Row } from 'reactstrap';
import i18next from 'i18next';
import OverallCard from '../OverviewDashboard/overallCardPieChart';

const InstagramSocialMediaGender = ({ dataChart }) => {
  console.log(dataChart, '**********');

  const Colors = ['#EC1A74', '#0064E0', '#F6B43B', '#FF925B'];
  const finalData = dataChart.map((item, index) => {
    return {
      name: item.name,
      value: item.value,
      itemStyle: {
        color: Colors[index],
      },
    };
  });
  return (
      <OverallCard
        data={finalData}
        type="percentage"
        style={{ width: '430px', height: '430px' }}
      />
    
  );
};

export default InstagramSocialMediaGender;
