import React, { useEffect, useState } from 'react';
import GoogleAdsCardPercentage from '../GoogleDashboard/Google Ads Overview/GoogleAdsCard/GoogleAdsCardPercentage';
import { useSelector } from 'react-redux';
import {
  formattedDated,
} from '../GoogleAnalytics/common/HelperFunctions/utils';

const PercentageWithCompare = ({ percentage }) => {
  const [compareDate, setCompareDate] = useState('');
  const { dateRange } = useSelector((state) => state.dateRangeReducer);

  useEffect(() => {
    if (dateRange.selection2.startDate && dateRange.selection2.endDate)
      setCompareDate(
        `Than ${formattedDated(
          dateRange.selection2.startDate
        )} - ${formattedDated(dateRange.selection2.endDate)}`
      );
    else setCompareDate('Select Compare');
  }, [dateRange]);

  return (
    <div className="d-flex align-items-center justify-content-center gap-2">
      <GoogleAdsCardPercentage percentage={percentage} />
      <span
        style={{
          color: '#1E1B39',
          fontSize: '11px',
          fontWeight: '500',
          fontFamily: 'DM Sans, sans-serif',
        }}
      >
        {compareDate}
      </span>
    </div>
  );
};

export default PercentageWithCompare;
