import React from 'react';
import { Row } from 'reactstrap';
import FbSocialMediaCard from './fbSocialMediaCard';
import {
  formatNumberWithSpaces,
  getTotal,
  getTotalFixed,
} from '../GoogleAnalytics/common/HelperFunctions/utils';
import GoogleAdsCard from '../GoogleDashboard/Google Ads Overview/GoogleAdsCard/GoogleAdsCard';

const FbSocialMediaCards = ({ secCards, colors }) => {
  const findDataByName = (name, value) => {
    const metric = secCards.find((item) => item.name === name);
    return metric ? metric[value] : [];
  };
  const displayedData = (item, data) => {
    switch (item.name) {
      case 'Followers':
        return (
          findDataByName('Followers', data)[
            findDataByName('Followers', data).length - 1
          ] || 0
        );
      case 'Likes':
        return (
          findDataByName('Likes', data)[
            findDataByName('Likes', data).length - 1
          ] || 0
        );
      case 'Monthly Impressions':
        return getTotal(findDataByName('Monthly Impressions', data));
      case 'Post Engagements':
        return getTotal(findDataByName('Post Engagements', data));
      case 'Post Impressions':
        return getTotal(findDataByName('Post Impressions', data));
      case 'Page Impressions Paid':
        return getTotal(findDataByName('Page Impressions Paid', data));
      case 'Page Posts Impressions Paid':
        return getTotal(findDataByName('Page Posts Impressions Paid', data));

      default:
        return null;
    }
  };

  const renderContent = (item, data) => {
    return formatNumberWithSpaces(displayedData(item, data));
  };

  const finalData = secCards.map((item) => {
    if (item.compareData && item.compareData.length > 0) {
      return {
        name: item.name,
        totalValue: renderContent(item, 'data'),
        percentage: (
          ((displayedData(item, 'data') - displayedData(item, 'compareData')) /
            displayedData(item, 'compareData')) *
          100
        ).toFixed(2),
        series: [
          { name: item.name, data: item.data },
          { name: `compare ${item.name}`, data: item.compareData },
        ],
      };
    } else
      return {
        name: item.name,
        totalValue: renderContent(item, 'data'),
        series: [{ name: item.name, data: item.data }],
      };
  });
  return (
    <>
      {finalData.map((item, index) => (
        <GoogleAdsCard item={item} key={index} colors={colors} />
      ))}
    </>
  );
};

export default FbSocialMediaCards;
