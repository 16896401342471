import { takeLatest, put, call, takeEvery } from 'redux-saga/effects';
import {
  getinstaPageAudience,
  getinstaPageData,
  getinstaPagePosts,
} from '../../queries/instagramSocialMedia/queries';
import urls from '../../routes/apiUrls';
import ApolloClient from 'apollo-boost';
import {
  GET_INSTA_PAGE_DATA,
  getInstaPageDataSuccess,
  getInstaPageDataFailure,
  GET_INSTA_POST_DATA,
  getInstaPostDataSuccess,
  getInstaPostDataFailure,
  GET_INSTA_AUDIENCE_DATA,
  getInstaAudienceDataSuccess,
  getInstaAudienceDataFailure,
} from './actions';

const ApolloCliGetInstagram = new ApolloClient({
  uri: urls.instagramSocialMedia,
});

function* getInstaPageData({ payload }) {
  try {
    const variables = {
      startDate: payload.startDate,
      endDate: payload.endDate,
    };

    if (payload.compareStartDate) {
      variables.compareStartDate = payload.compareStartDate;
    }

    if (payload.compareEndDate) {
      variables.compareEndDate = payload.compareEndDate;
    }
    const { data } = yield call(ApolloCliGetInstagram.query, {
      query: getinstaPageData,
      variables,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache', //allow me to refetch data
    });
    if (data) {
      yield put(getInstaPageDataSuccess(data.getinstagramPageData));
    } else {
      yield put(getInstaPageDataFailure('err'));
    }
  } catch (error) {
    yield put(getInstaPageDataFailure(error));
  }
}

function* getInstaPostData() {
  try {
    const { data } = yield call(ApolloCliGetInstagram.query, {
      query: getinstaPagePosts,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    if (data) {
      yield put(getInstaPostDataSuccess(data.getinstagramPagePosts));
    } else {
      yield put(getInstaPostDataFailure('err'));
    }
  } catch (error) {
    yield put(getInstaPostDataFailure(error));
  }
}

function* getInstaAudienceData() {
  try {
    const { data } = yield call(ApolloCliGetInstagram.query, {
      query: getinstaPageAudience,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache', //allow me to refetch data
    });
    if (data) {
      yield put(getInstaAudienceDataSuccess(data.getInstagramPageAudience));
    } else {
      yield put(getInstaAudienceDataFailure('err'));
    }
  } catch (error) {
    yield put(getInstaAudienceDataFailure(error));
  }
}

export default function* instagramSaga() {
  yield takeEvery(GET_INSTA_PAGE_DATA, getInstaPageData);
  yield takeEvery(GET_INSTA_POST_DATA, getInstaPostData);
  yield takeEvery(GET_INSTA_AUDIENCE_DATA, getInstaAudienceData);
}
