import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { useSelector } from 'react-redux';

const generateDateRange = (startDate, endDate) => {
  const dates = [];
  let currentDate = new Date(startDate);

  while (currentDate <= new Date(endDate)) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

const OperationsChart = ({ metricData, colors }) => {
  const { dateRange } = useSelector((state) => state.dateRangeReducer);

  const getOption = () => {
    return {
      tooltip: {
        trigger: 'axis',
        backgroundColor: '#fff',
        borderColor: colors.lineColor,
        borderWidth: 1,
        textStyle: {
          color: '#000',
        },
      },
      grid: {
        left: '2%',
        right: '6%',
        top: '10%',
        bottom: '10%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: generateDateRange(
          dateRange.selection1.startDate,
          dateRange.selection1.endDate
        ).map(item =>
          item.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short',
          })
        ),
        axisTick: { show: false },
        axisLabel: {
          color: '#000000',
          align: 'center',
          padding: [7, 0, 0, 0],
          fontSize: 14,
          fontFamily: 'Nunito Sans, sans-serif',
          fontWeight: 400,
        },
        axisLine: {
          lineStyle: {
            color: '#33343E',
          },
        },
      },
      yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: '#74788d',
          },
        },
        axisLabel: {
          color: '#000000',
          align: 'right',
          fontSize: 14,
          fontFamily: 'Nunito Sans, sans-serif',
          fontWeight: 400,
        },
      },
      series: [{
        name: metricData.name,
        data: metricData.data.map(dataPoint => dataPoint.toFixed(2)),
        type: 'line',
        smooth: true,
        symbol: 'circle',
        symbolSize: 10,
        showSymbol: false,
        itemStyle: {
          color: colors.lineColor,
          borderColor: '#FFFFFF',
          borderWidth: 2,
        },
        lineStyle: {
          width: 2,
          color: colors.lineColor,
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: colors.fillColor,
          },
        },
        z: 3,
      }],
    };
  };

  return (
    <div>
      <ReactEcharts
        option={getOption()}
        style={{ height: '400px', width: '100%' }}
      />
    </div>
  );
};

export default OperationsChart;
