import { Button, Checkbox, Dropdown, Menu, Space, Table } from 'antd';
import React, { useState } from 'react';
import { Card, CardBody, Row } from 'reactstrap';
import { formatNumberWithSpaces } from '../GoogleAnalytics/common/HelperFunctions/utils';
import GoogleAdsTable from '../GoogleDashboard/GoogleAdsTable';

const FacebookSocialMediaIcon = `${process.env.PUBLIC_URL}/images/integrations_images/fb1.svg`;

const FbSocialMediaPostsTable = ({ dataSource }) => {
  const [filteredData, setFilteredData] = useState(dataSource);
  const [sortedInfo, setSortedInfo] = useState({});
  const columns = [
    {
      title: 'Full Picture',
      dataIndex: 'full_picture',
      key: 'full_picture',
      align: 'center',
      ellipsis: true,
      render: (text, record) => {
        if (!text) {
          return (
            <a
              href={record.permalink_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>There is No Image</span>
            </a>
          );
        } else {
          return (
            <a
              href={record.permalink_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={text}
                alt={` ${record.full_picture}`}
                style={{ maxWidth: '100px', maxHeight: '90px' }}
              />
            </a>
          );
        }
      },
    },
    {
      title: 'Post Clicks',
      dataIndex: 'post_clicks',
      key: 'post_clicks',
      align: 'center',
      sorter: (a, b) => a.post_clicks - b.post_clicks,
      sortOrder:
        sortedInfo.columnKey === 'post_clicks' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: 'Post Likes',
      dataIndex: 'post_likes',
      key: 'post_likes',
      align: 'center',
      sorter: (a, b) => a.post_likes - b.post_likes,
      sortOrder:
        sortedInfo.columnKey === 'post_likes' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: 'Post Total Reactions',
      dataIndex: 'postReactionsTotal',
      key: 'postReactionsTotal',
      align: 'center',
      sorter: (a, b) => a.postReactionsTotal - b.postReactionsTotal,
      sortOrder:
        sortedInfo.columnKey === 'postReactionsTotal' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: 'Post Impressions',
      dataIndex: 'post_impressions',
      key: 'post_impressions',
      align: 'center',
      sorter: (a, b) => a.post_impressions - b.post_impressions,
      sortOrder:
        sortedInfo.columnKey === 'post_impressions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: 'Post Engagements',
      dataIndex: 'post_engagements',
      key: 'post_engagements',
      align: 'center',
      sorter: (a, b) => a.post_engagements - b.post_engagements,
      sortOrder:
        sortedInfo.columnKey === 'post_engagements' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: 'Impressions Paid',
      dataIndex: 'post_impressions_paid',
      key: 'post_impressions_paid',
      align: 'center',
      sorter: (a, b) => a.post_impressions_paid - b.post_impressions_paid,
      sortOrder:
        sortedInfo.columnKey === 'post_impressions_paid'
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: 'Negative Feedback',
      dataIndex: 'post_negative_feedback',
      key: 'post_negative_feedback',
      align: 'center',
      sorter: (a, b) => a.post_negative_feedback - b.post_negative_feedback,
      sortOrder:
        sortedInfo.columnKey === 'post_negative_feedback'
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: 'Shares',
      dataIndex: 'shares',
      key: 'shares',
      align: 'center',
      sorter: (a, b) => a.shares - b.shares,
      sortOrder: sortedInfo.columnKey === 'shares' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (text) => {
        const formattedDate = text ? new Date(text).toLocaleDateString() : '';
        return <strong>{formattedDate}</strong>;
      },
    },
  ];

  return (
    
      <GoogleAdsTable
        columns={columns}
        setFilteredData={setFilteredData}
        filteredData={filteredData}
        data={dataSource}
        sortedInfo={sortedInfo}
        setSortedInfo={setSortedInfo}
        mainSorterKey='post_likes'
        
      />
    
  );
};

export default FbSocialMediaPostsTable;
