import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getGAPlatformCompraisonRequest,
  getGAPlatformRequest,
} from '../../../store/actions';
import './CardStyle.css';
import { formatDate } from '../common/HelperFunctions/utils';
import OverallCard from '../../OverviewDashboard/overallCardPieChart';

function GADeviceCategory() {
  const dispatch = useDispatch();
  const { gaPlatform, loading } = useSelector((state) => state.googleAnalytics);
  const [chartData, setChartData] = useState([]);
  const [compareChartData, setCompareChartData] = useState([]);
  const { dateRange } = useSelector((state) => state.dateRangeReducer);

  useEffect(() => {
    const newStartDate = formatDate(dateRange.selection1.startDate);
    const newEndDate = formatDate(dateRange.selection1.endDate);

    if (dateRange.selection2.startDate && dateRange.selection2.startDate) {
      const compareStartDateFormatted = formatDate(
        dateRange.selection2.startDate
      );
      const compareEndDateFormatted = formatDate(dateRange.selection2.endDate);

      dispatch(
        getGAPlatformCompraisonRequest(
          newStartDate,
          newEndDate,
          compareStartDateFormatted,
          compareEndDateFormatted
        )
      );
    } else {
      dispatch(getGAPlatformRequest(newStartDate, newEndDate));
    }
  }, [dateRange]);

  const calculateGrowth = (current, previous) => {
    if (previous === 0) return '∞'; // Handle division by zero
    const growth = ((current - previous) / previous) * 100;
    return `${growth > 0 ? '+' : ''}${growth.toFixed(2)}%`;
  };

  const deviceCategorie = (gaPlatform) => {
    const deviceCategories = gaPlatform.flatMap(
      (platform) => platform.deviceCategory
    );
    const categories = [
      ...new Set(deviceCategories.map((item) => item.deviceCategory)),
    ];
    const data = categories.map((category) => {
      const totalUsers = deviceCategories
        .filter((item) => item.deviceCategory === category)
        .reduce((sum, item) => sum + item.totalUsers, 0);
      return { name: category, value: totalUsers };
    });
    return data;
  };

  useEffect(() => {
    if (gaPlatform && gaPlatform.length > 0) {
      const currentData = deviceCategorie(gaPlatform[0]);
      let previousData = [];
      if (gaPlatform[1]) {
        previousData = deviceCategorie(gaPlatform[1]);

        const dataWithGrowth = currentData.map((currentItem) => {
          const previousItem = previousData.find(
            (item) => item.name === currentItem.name
          );
          const previousValue = previousItem ? previousItem.value : 0;
          const growth = calculateGrowth(currentItem.value, previousValue);
          return { ...currentItem, name: `${currentItem.name} (${growth})` };
        });
      }
      setCompareChartData(previousData);

      setChartData(currentData);
    }
  }, [gaPlatform]);


  const Colors = ['#EC1A74', '#0064E0', '#F6B43B', '#FF925B'];
  const finalData = chartData.map((item, index) => {
    return {
      name: item.name,
      value: item.value,
      itemStyle: {
        color: Colors[index],
      },
    };
  });
  const finalCompareData = compareChartData.map((item, index) => {
    return {
      name: item.name,
      value: item.value,
      itemStyle: {
        color: Colors[index],
      },
    };
  });
  console.log(chartData, compareChartData);
  return (
      <div className="d-flex justify-content-center align-items-center mt-4">
        <OverallCard
          data={finalData}
          type="percentage"
          style={{ width: '500px', height: '500px' }}
        />
        {dateRange.selection2.startDate && (
          <OverallCard
            data={finalCompareData}
            type="percentage"
            style={{ width: '500px', height: '500px' }}
          />
        )}
      </div>
  );
}

export default GADeviceCategory;
