import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { CloseOutlined } from '@ant-design/icons';
import { Tooltip as AntdTooltip } from 'antd';
import Tooltip from '../GoogleDashboard/Google Ads Overview/GoogleAdsCard/Tooltip';

import PercentageWithCompare from './PercentageWithCompare';
import { tooltipMap } from '../../locales/tooltip/tooltip';

const DeleteableMetricCard = ({ item, setSelectedGroups }) => {
  // item will be an object like this {key: 1, label: 'Total Revenue', value: 1000,percentage: 20}

  const handleHideCard = (cardIndex) => {
    setSelectedGroups((prevGroups) =>
      prevGroups.filter((group) => group.key !== cardIndex)
    );
  };
  return (
    <Col xl={2} md={4} key={item.key}>
      <Card className="text-center">
        <CardBody style={{ padding: '20px' }}>
          <div className=" d-flex justify-content-between align-items-center">
            <Tooltip
              displayed={
                <span
                  style={{
                    color: '#0044BD',
                    fontSize: '13px',
                    fontFamily: 'DM Sans , sans-serif',
                    fontWeight: 700,
                    cursor: 'pointer',
                  }}
                >
                  {item.label}
                </span>
              }
              tooltip={tooltipMap[item.label]?.tooltip}
              formule={tooltipMap[item.label]?.formule}
            />

            <AntdTooltip title="Delete Metric">
              <CloseOutlined
                onClick={() => handleHideCard(item.key)}
                style={{ cursor: 'pointer', fontSize: '12px' }}
              />
            </AntdTooltip>
          </div>
          <Row className="justify-content-center mb-3 mt-3">
            <Col xs={12} className="d-flex justify-content-start">
              <span
                style={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                  fontFamily: 'DM Sans , sans-serif',
                }}
              >
                {item.value}
              </span>
            </Col>
          </Row>
          <Row className="justify-content-center ">
            <Col xs={12} className="d-flex justify-content-start"></Col>
            {item.percentage && (
              <PercentageWithCompare percentage={item.percentage} />
            )}
            <Col />
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default DeleteableMetricCard;
