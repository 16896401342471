import React from 'react';
import arrowDown from '../../../../assets/images/icons/arrow-down.svg';
import arrowup from '../../../../assets/images/icons/arrow-up.svg';

const GoogleAdsCardPercentage = ({ percentage }) => {
  const isPositive = percentage >= 0;
  if (percentage === '-') {
    return (
      <div
        className=" d-flex justify-content-center align-items-center"
        style={{
          backgroundColor: '#E7E7E7',
          width: '80px',
          height: '23px',
          borderRadius: '2px',
          padding: '2px 5px',
        }}
      >
        {' '}
        <span
          style={{
            fontSize: '13px',
            fontWeight: '700',
            color: '#5D5D5D',
          }}
        >
          -
        </span>{' '}
      </div>
    );
  }
  return (
    <div
      className=" d-flex justify-content-center align-items-center gap-1"
      style={{
        backgroundColor: isPositive ? '#CDFFCD' : '#FFE0E0',
        width: '80px',
        height: '23px',
        borderRadius: '2px',
        padding: '2px 5px',
      }}
    >
      <span
        style={{
          fontSize: '13px',
          fontWeight: '700',
          color: isPositive ? '#007F00' : '#D30000',
        }}
      >
        {Math.abs(percentage)}%
      </span>
      <img
        src={isPositive ? arrowup : arrowDown}
        style={{
          width: '14px',
          height: '11px',
        }}
        alt={isPositive ? 'icon arrow up' : 'icon arrow down'}
      />
    </div>
  );
};

export default GoogleAdsCardPercentage;
